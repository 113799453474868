





















import type { PropType } from '@nuxtjs/composition-api';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';
import { SocialIconInterface } from '~/modules/amplience/types';
import { SfLink } from '@storefront-ui/vue';
import { getImageData } from '@amplience/helpers/getImageData';

export default defineComponent({
  name: 'SocialIcons',
  components: {
    SfLink,
  },
  props: {
    items: {
      type: Array as PropType<SocialIconInterface[]>,
      default: () => [],
    },
    touchTarget: {
      type: Number,
      default: 44,
    },
  },
  setup(props) {
    const element = ref<HTMLElement | null>(null);
    const isHeightChanged = ref<boolean>(false);

    const handleResize = (): void => {
      isHeightChanged.value = element.value.offsetHeight > props.touchTarget;
    };

    onMounted(() => {
      handleResize();
      window.addEventListener('resize', handleResize);
    })

    onBeforeUnmount(() => {
      window.addEventListener('resize', handleResize);
    })

    return {
      element,
      getImageData,
      isHeightChanged,
    };
  },
});
