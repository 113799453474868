import { computed, useContext, ComputedRef } from '@nuxtjs/composition-api';
import { isDualRunning } from '@theme/helpers/dualRunning';

export interface UseFeatureToggleInterface {
  isCustomPriceEnabled: ComputedRef<boolean>;
  isDualRunningEnabled: ComputedRef<boolean>;
}

export function useFeatureToggle(): UseFeatureToggleInterface {
  const {
    app: { $featureToggle, $cookies },
  } = useContext();

  return {
    isCustomPriceEnabled: computed(() => $featureToggle.toggles['is-custom-price-enabled'] || false),
    isDualRunningEnabled: computed(() => isDualRunning($cookies)),
  };
}

export default useFeatureToggle;
