










































import { defineComponent } from '@nuxtjs/composition-api';
import { ValidationInterface } from '@theme/components/form/types';
import { ValidationObserver } from 'vee-validate';
import Errors from '@theme/components/form/Errors.vue';
import { useScrollToElement } from '@theme/modules/catalog/composables/useScrollToElement';
import { SfButton, SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'FormElement',
  components: {
    ValidationObserver,
    SfButton,
    SfLoader,
    Errors,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    errorsHeading: {
      type: String,
      default: 'There is a problem',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitButtonLabel: {
      type: String,
      default: 'Submit'
    }
  },
  setup(props, { emit }) {
    const { scrollTo } = useScrollToElement();

    const submitForm = async (validateWithInfo) => {
      const formState: ValidationInterface = await validateWithInfo();

      if (formState.isValid) {
        emit('submit');
      } else {
        emit('on-invalid');
        scrollTo(props.id);
      }
    }

    return {
      submitForm,
    }
  }
})
