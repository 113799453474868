export default ({ $config, app }) => {
  window.addEventListener('pageshow', (event) => {
    const urls = $config.bfCacheUrls;
    const path = location.pathname.split('/')[1] || '';

    /**
     * Triggering page reload after bfcache restore
     * https://web.dev/articles/bfcache#update-data-after-restore
     * */
    if (
      event.persisted &&
      urls.includes(path) &&
      !app.$vsf.$magento.config.state.getCustomerToken()
    ) {
      location.reload();
    }
  });
}
