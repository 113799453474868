
































































































import {
  defineComponent,
  computed,
  PropType,
  useContext,
} from '@nuxtjs/composition-api';
import { ValidationProvider } from 'vee-validate';
import { vMaska } from 'maska';
import type { NotificationMessage } from '~/composables/useUiNotification/useUiNotification';
import Notification from '@theme/components/molecules/Notification.vue';
import TouchTarget from '@theme/components/atoms/TouchTarget.vue';

export default defineComponent({
  name: 'TextField',
  components: {
    ValidationProvider,
    TouchTarget,
    Notification,
  },
  directives: {
    maska: vMaska
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    helper: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    warning: {
      type: Object as PropType<NotificationMessage>,
      default: null,
    },
    mask: {
      type: String as PropType<'number'>,
      default: null,
    },
    vid: {
      type: String,
      default: null,
    },
    tabIndex: {
      type: String,
      default: '0',
    },
    placeholder: {
      type: String,
      default: '',
    },
    customMessages: {
      type: Object,
      default: () => {}
    },
    validateImmediately: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const context = useContext();

    // Infer additional rules from component attributes
    const validationRules = computed(() => {
      let rules = props.rules;

      if (props.required === true) {
        rules = rules ? `required|${rules}` : 'required';
      }

      return rules;
    });

    const maskOptions = {
      postProcess: val => {
        if (!val) return '';

        switch (props.mask) {
        case 'number':
          return Intl.NumberFormat(
            context.i18n?.localeProperties?.iso.replace('_', '-'),
            { minimumFractionDigits: 2 },
          ).format(val);

        default:
          return val;
        }
      }
    };

    return {
      validationRules,
      maskOptions,
    }
  },
});
