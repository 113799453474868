import {
  UseScrollToElementInterface
} from '~/bbrTheme/modules/catalog/composables/useScrollToElement/useScrollToElement';

export const useScrollToElement = (): UseScrollToElementInterface => {
  const scrollTo = (id: string): void => {
    const elementPosition = window.document.getElementById(id)?.offsetTop + 120;
    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  }

  return {
    scrollTo,
  }
}