/**
 * key - is the schema identifier of the amplience entity
 * value - is an import name defined in the components object which used in pages/Page.vue
 */
export const schemaPages = {
  'https://www.bbr.com/slot/article': 'ARTICLE_VIEW',
  'https://www.bbr.com/slot/article-landing': 'ARTICLE_LANDING',
  'https://www.bbr.com/slot/article-category': 'ARTICLE_CATEGORY',
  'https://www.bbr.com/slot/content-page': 'CONTENT_PAGE',
  'https://www.bbr.com/slot/campaign-page': 'CAMPAIGN_PAGE',
  'https://www.bbr.com/slot/customer-support': 'CUSTOMER_SUPPORT_PAGE',
  'https://www.bbr.com/slot/customer-support-landing': 'CUSTOMER_SUPPORT_LANDING',
  'https://www.bbr.com/slot/contact-us-page': 'CONTACT_US_PAGE',
  'https://www.bbr.com/slot/regions-page': 'REGIONS_PAGE',
} as const;

/**
 * key - is the schema identifier of the amplience entity
 * value - the component file name responsible for the visual rendering
 */
export const schemaComponents = {
  'https://www.bbr.com/content-type/article': 'Article',
  'https://www.bbr.com/content-type/audio-podcast': 'AudioPodcast',
  'https://www.bbr.com/content-type/author': 'Author',
  'https://www.bbr.com/content-type/banner': 'Banner',
  'https://www.bbr.com/content-type/body-image': 'BodyImage',
  'https://www.bbr.com/content-type/hero-banner': 'HeroBanner',
  'https://www.bbr.com/content-type/social-sharing': 'SocialSharing',
  'https://www.bbr.com/content-type/video-amplience': 'VideoAmplience',
  'https://www.bbr.com/content-type/video-youtube': 'VideoYoutube',
  'https://www.bbr.com/content-type/quote': 'Quote',
  'https://www.bbr.com/content-type/tag': 'Tag',
  'https://www.bbr.com/content-type/recipe': 'Recipe',
  'https://www.bbr.com/content-type/feature-block': 'FeatureBlock',
  'https://www.bbr.com/content-type/article-card-list': 'ArticleCardList',
  'https://www.bbr.com/content-type/footer': 'AmplienceFooter',
  'https://www.bbr.com/content-type/global-footer': 'GlobalFooter',
  'https://www.bbr.com/content-type/social-networks': 'SocialNetworks',
  'https://www.bbr.com/content-type/social-icons': 'SocialIcons',
  'https://www.bbr.com/content-type/contact-points': 'ContactPoints',
  'https://www.bbr.com/content-type/navigation': 'Navigation',
  'https://www.bbr.com/content-type/header': 'AmplienceHeader',
  'https://www.bbr.com/content-type/rich-text': 'RichText',
  'https://www.bbr.com/content-type/category-hero-banner': 'CategoryHeroBanner',
  'https://www.bbr.com/content-type/migration-body-image': 'MigrationBodyImage',
  'https://www.bbr.com/content-type/accordion': 'Accordion',
  'https://www.bbr.com/content-type/image-half-width-with-text': 'ImageHalfWidth',
  'https://www.bbr.com/content-type/feature-block-shop': 'ShopsFeatureBlock',
  'https://www.bbr.com/content-type/core-values': 'CoreValues',
  'https://www.bbr.com/content-type/promo-bar': 'PromoBar',
  'https://www.bbr.com/content-type/error': 'Error404',
  'https://www.bbr.com/content-type/table': 'Table',
  'https://www.bbr.com/content-type/product-body': 'ProductBody',
  'https://www.bbr.com/content-type/product-body-spirits': 'ProductBodySpirit',
  'https://www.bbr.com/content-type/product-body-after-content': 'ProductBodyAfterContent',
  'https://www.bbr.com/content-type/info-block': 'InfoBlock',
  'https://www.bbr.com/content-type/anchor-button': 'AnchorButton',
  'https://www.bbr.com/content-type/grape': 'FeatureBlockAlt',
  'https://www.bbr.com/content-type/region': 'FeatureBlockAlt',
  'https://www.bbr.com/content-type/producer': 'FeatureBlockAlt',
  'https://www.bbr.com/content-type/jumbo-hero-banner': 'JumboHeroBanner',
  'https://www.bbr.com/content-type/vintage-charts-placeholder': 'VintageChartsPlaceholder',
  'https://www.bbr.com/content-type/usp-info': 'UspInfo',
  'https://www.bbr.com/content-type/product-lister': 'ProductLister',
  'https://www.bbr.com/content-type/global-header': 'GlobalHeader',
  'https://www.bbr.com/content-type/related-products-placeholder': 'RelatedProductsPlaceholder',
  'https://www.bbr.com/content-type/country-selector': 'CountrySelector',
  'https://www.bbr.com/content-type/simple-header': 'SimpleHeader',
  'https://www.bbr.com/content-type/simple-footer': 'SimpleFooter',
  'https://www.bbr.com/content-type/heading-with-cta': 'HeadingWithCta',
  'https://www.bbr.com/content-type/contact-enquiries': 'ContactEnquiries',
  'https://www.bbr.com/content-type/contact-info': 'ContactInfo',
  'https://www.bbr.com/content-type/shops': 'Shops',
  'https://www.bbr.com/content-type/email-sign-up': 'EmailSignUp',
  'https://www.bbr.com/content-type/register-interest': 'RegisterInterest',
  'https://www.bbr.com/content-type/cellar-cards-overview': 'CellarCardsOverview',
} as const;
