






import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/modules/amplience/composables/useContent';
import AmplienceHeader from '@amplience/components/content-type/AmplienceHeader.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    AmplienceHeader,
  },
  setup() {
    const { search, data } = useContent();

    useFetch(async () => {
      await search('header', 'url');
    });

    return {
      data,
    };
  },
});
