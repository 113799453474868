/**
 * Transfoms cookie string from the request object into a keyed cookie array
 */
module.exports.getCookies = (req) => {
  const cookies = {};
  const cookiesArray = req?.headers?.cookie?.split(';') || [];

  cookiesArray.forEach((cookie) => {
    const [key, value] = cookie.trim().split('=');

    cookies[key] = value;
  });

  return cookies;
};
