















import type { PropType } from '@nuxtjs/composition-api';
import type { ExtensiveLogo, SecurePayment as SecurePaymentInterface } from '@amplience/types';
import { defineComponent } from '@nuxtjs/composition-api';
import Logo from '@amplience/components/atoms/Logo.vue';
import SecurePayment from '@amplience/components/molecules/header/SecurePayment.vue';

export default defineComponent({
  name: 'SimpleHeader',
  components: {
    SecurePayment,
    Logo,
  },
  props: {
    logo: {
      type: Object as PropType<ExtensiveLogo>,
      default: () => ({
        image: null,
        label: '',
      }),
    },
    securePayment: {
      type: Object as PropType<SecurePaymentInterface>,
      default: () => ({
        image: null,
        label: '',
        enabled: false,
      }),
    },
  },
});
