import {
  fragmentCustomerAddresses,
  fragmentCustomerDetails,
  fragmentCustomerStoreCredit,
} from './fragments';

export default `
  mutation updateCustomer($input: CustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        ...CustomerDetails
        ...CustomerStoreCredit
        ...CustomerAddresses
      }
    }
  }
  ${fragmentCustomerDetails}
  ${fragmentCustomerStoreCredit}
  ${fragmentCustomerAddresses}
`;
