























import { defineComponent, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import Countries from '@amplience/components/molecules/header/Countries.vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { useNavigation } from '@amplience/stores/navigation';
import { storeToRefs } from 'pinia';
import type { Country } from '@amplience/types';

export default defineComponent({
  name: 'CountrySelector',
  directives: {
    clickOutside
  },
  components: {
    SfButton,
    Countries
  },
  props: {
    countries: {
      type: Array as PropType<Country[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { $config } = useContext();
    const navigation = useNavigation();
    const { currentCountry } = storeToRefs(navigation);
    const isExpanded = ref<boolean>(false);

    const toggleCountries = () => isExpanded.value = !isExpanded.value;

    const closeCountries = (event) => {
      if (event.target.id !== 'expand-trigger') {
        isExpanded.value = false
      }
    };

    onMounted(() => {
      const currentCountry = props.countries?.find(country => $config.baseURL === country.url);

      if (currentCountry) {
        navigation.setCurrentCountry(currentCountry.name);
      }
    });

    return {
      currentCountry,
      isExpanded,
      toggleCountries,
      closeCountries,
    }
  }
})
