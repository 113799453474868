export default `
  query getCategories ($name: String, $root_category_url_keys: [String]) {
    categories: categoriesSearch(name: $name, root_category_url_keys: $root_category_url_keys) {
      items {
        id
        code
        name
        uid
        url_key
        url_path
      }
    }
  }
`;
