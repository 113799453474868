






























import { SfButton } from '@storefront-ui/vue';
import { computed, defineComponent, ref, toRefs } from '@nuxtjs/composition-api';
import { colorIDs } from '~/bbrTheme/modules/catalog/getters/colorsData';
import MaturityStatusSidebar from '@cellar-services/components/organisms/Sidebar/MaturityStatusSidebar.vue'
import { MATURITY_ICON_ROTATION } from '~/bbrTheme/modules/catalog/constants/attributes/maturity';

export default defineComponent({
  name: 'Maturity',
  components: {
    SfButton,
    MaturityStatusSidebar,
  },
  props: {
    maturity: {
      type: String,
      required: true,
    },
    colour: {
      type: String,
      required: true,
    },
    windowFrom: {
      type: String,
      default: null,
    },
    windowTo: {
      type: String,
      default: null,
    },
    withLink: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { colour, maturity } = toRefs(props);
    const isOpen = ref(false);
    const maturityRotation = computed(() => MATURITY_ICON_ROTATION[maturity.value]);
    const colourCode = computed(() => colorIDs[colour.value] || 'black');

    return {
      maturityRotation,
      colourCode,
      isOpen,
    };
  },
});
