














import type { PropType } from '@nuxtjs/composition-api';
import {
  defineComponent,
  computed,
  toRef,
  useContext,
} from '@nuxtjs/composition-api';
import { Logo as LogoInterface, RoyalWarrant } from '@amplience/types';
import Logo from '@amplience/components/atoms/Logo.vue';

export default defineComponent({
  name: 'RoyalWarrants',
  components: {
    Logo,
  },
  props: {
    warrants: {
      type: Array as PropType<RoyalWarrant[]>,
      default: () => [],
    },
    defaultWarrants: {
      type: Array as PropType<LogoInterface[]>,
      default: () => [
        {
          url: '/bbr/royal-warrants/p.svg',
          alt: 'Royal warrant by appointment to H.R.H the Prince of Wales wine & spirit merchant London',
        },
        {
          url: '/bbr/royal-warrants/q.svg',
          alt: 'Royal warrant by appointment to H.M The Queen wine & spirit merchant London',
        },
      ],
    },
  },
  setup(props) {
    const { i18n } = useContext();
    const warrants = toRef(props, 'warrants');

    const preparedWarrants = computed(() => {
      if (warrants.value?.length) {
        return warrants.value.map((warrant: RoyalWarrant) => ({
          key: warrant.image._meta.deliveryId,
          isLink: false,
          alt: warrant.label,
          image: warrant.image,
          defaultLogo: null,
        }));
      }

      return props.defaultWarrants.map((warrant: LogoInterface) => ({
        key: warrant.url,
        isLink: false,
        image: null,
        alt: i18n.t(warrant.alt),
        defaultLogo: warrant,
      }));
    });

    return {
      preparedWarrants,
    };
  },
});
