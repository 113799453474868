
import { defineComponent } from '@nuxtjs/composition-api';
import GlobalFooter from '@amplience/components/content-type/GlobalFooter.vue';

export default defineComponent({
  name: 'SimpleFooter',
  mixins: [GlobalFooter],
  props: {
    isGlobalFooter: {
      type: Boolean,
      default: false,
    },
  },
});
