

















































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { useNavigation } from '@amplience/stores/navigation';
import type { NavigationsInterface } from '@amplience/types';
import Parent from '@amplience/components/atoms/navigation/Parent.vue';
import Group from '@amplience/components/atoms/navigation/Group.vue';
import Child from '@amplience/components/atoms/navigation/Child.vue';

export default defineComponent({
  name: 'DesktopMegaMenu',
  components: {
    Parent,
    Group,
    Child,
  },
  props: {
    navigations: {
      type: Array as PropType<NavigationsInterface[]>,
      required: true,
    },
  },
  setup() {
    const navigationStore = useNavigation();
    const { getSortedChildrenById, getParentByGroupId } = storeToRefs(navigationStore);
    const isChildFocused = ref<boolean>(false);
    const focusableParent = ref<string | null>(null);
    const childFocusTimeout = ref<number | NodeJS.Timeout | null>(null);

    const handleChildFocusIn = (id: string): void => {
      const parent = getParentByGroupId.value(id);

      if (childFocusTimeout !== null) {
        clearTimeout(childFocusTimeout.value as number);
      }

      focusableParent.value = parent ? parent.deliveryId : null;
      isChildFocused.value = true;
    }

    const handleChildFocusOut = (): void => {
      childFocusTimeout.value = setTimeout(() => isChildFocused.value = false, 100);
    }

    const isFocused = (id: string): boolean => {
      return isChildFocused.value && focusableParent.value === id;
    }

    return {
      getSortedChildrenById,
      isChildFocused,
      isFocused,
      handleChildFocusIn,
      handleChildFocusOut,
    }
  }
})
