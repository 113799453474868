import { parsePhoneNumber, isPossiblePhoneNumber } from 'libphonenumber-js';
import { useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';

const ERROR_MESSAGE = '[ERROR] Failed to parse phone number:';

export interface UsePhoneNumber {
  getCountryCode: (phoneNumber: string) => string | null;
}

/**
 * Provides a method to get the country code from a phone number.
 *
 * @returns {UsePhoneNumber} An object with a `getCountryCode` method.
 */
export const usePhoneNumber = (): UsePhoneNumber => {
  const { $config } = useContext();

  /**
   * Gets the country code from a phone number.
   *
   * If the phone number is not a possible phone number, it returns the default country code.
   * If the phone number can be parsed, it returns the country code of the parsed number.
   * If the phone number cannot be parsed, it returns the default country code.
   *
   * @param {string} phoneNumber - The phone number to get the country code from.
   * @returns {string | null} The country code, or null if no country code could be determined.
   */
  const getCountryCode = (phoneNumber: string): string | null => {
    if (!isPossiblePhoneNumber(phoneNumber)) {
      return $config.defaultCountryCode;
    }

    try {
      const parsedNumber = parsePhoneNumber(phoneNumber);

      if (!parsedNumber) {
        return $config.defaultCountryCode;
      }

      return parsedNumber.country;
    } catch (error) {
      Logger.debug(ERROR_MESSAGE, error);

      return $config.defaultCountryCode;
    }
  };

  return {
    getCountryCode,
  }
}

export default usePhoneNumber;
