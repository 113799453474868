import { Middleware } from '@nuxt/types';
import { Logger } from '~/helpers/logger';

const middleware: Middleware = async (context) => {
  try {
    const result = await context.$vsf.$magento.api.route(context.route.params.slug);

    if (!result.data.route) context.error({ statusCode: 404, message: 'Category not found' });
  } catch (error) {
    Logger.error('Error fetching category:', error);
    context.error({ statusCode: 500, message: 'Failed to fetch category' });
  }
};

export default middleware;
