























import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Country, ExtensiveLogo, NavigationInterface } from '@amplience/types';
import Logo from '@amplience/components/atoms/Logo.vue';
import GlobalHeaderNavigation from '@amplience/components/content-type/GlobalHeaderNavigation.vue';
import CountrySelector from '@amplience/components/molecules/header/CountrySelector.vue';
import ActionItemMenu from '@amplience/components/atoms/navigation/Menu.vue';

export default defineComponent({
  name: 'GlobalHeader',
  components: {
    ActionItemMenu,
    CountrySelector,
    Logo,
    GlobalHeaderNavigation,
  },
  props: {
    countries: {
      type: [Array, null] as PropType<Country[]>,
      default: () => [],
    },
    navigation: {
      type: Object as PropType<NavigationInterface>,
      required: true,
    },
    logo: {
      type: Object as PropType<ExtensiveLogo>,
      default: () => ({
        image: null,
        label: 'Header logo',
      }),
    },
  },
});
