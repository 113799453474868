





















import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { ImageInterface, Logo } from '@amplience/types';
import { getImageData } from '@amplience/helpers/getImageData';

export default defineComponent({
  name: 'Logo',
  components: {
    SfLink,
  },
  props: {
    isLink: {
      type: Boolean,
      default: true,
    },
    alt: {
      type: String,
      default: '',
    },
    logo: {
      type: [Object, null] as PropType<ImageInterface | null>,
      default: null,
    },
    defaultLogo: {
      type: Object as PropType<Logo>,
      default: () => ({
        url: '/bbr/logo/black.svg',
        alt: 'Logo',
      }),
    },
  },
  setup(props) {
    const logoData = computed<Logo>(() => (
      props.logo ? getImageData(props.logo) : props.defaultLogo
    ));

    return {
      logoData,
    };
  },
});
