//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfQuantitySelector } from '@storefront-ui/vue'
export default {
  name: 'BbrQuantitySelector',
  extends: SfQuantitySelector,
  props: {
    ariaLabel: {
      type: String,
      default: 'Quantity'
    },
    min: {
      type: Number,
      default: 1
    },
    step: {
      type: Number,
      default: 1
    }
  },
  handleKeydown(event, listeners) {
    // Ensure that it is a number and stop the keypress
    // Allow only backspace, delete and arrows
    if (!(event.keyCode === 8 || event.keyCode === 46 ||
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      (event.keyCode >= 37 && event.keyCode <= 40))) {
      event.preventDefault()
    }

    return listeners.keydown && listeners.keydown()
  },
  handleBlur(event, listeners, min, max) {
    const value = event.target.value
    const minimum = min || 1

    if (value < minimum || isNaN(value)) {
      event.target.value = minimum
    } else if (max !== null && value > max) {
      event.target.value = max
    }

    return listeners.blur && listeners.blur()
  }
}
