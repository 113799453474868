








































import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { SfLink } from '@storefront-ui/vue';
import { NavigationItem } from '@amplience/types';
import { useNavigation } from '@amplience/stores/navigation';
import { getImageData } from '@amplience/helpers/getImageData';

export default defineComponent({
  name: 'Accordion',
  components: {
    SfLink,
  },
  props: {
    item: {
      type: Object as PropType<NavigationItem>,
      required: true,
    },
    isCollapsedOnMobile: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useNavigation();
    const isVisible = ref<boolean>(props.isCollapsedOnMobile ? false : true);
    const { getSortedChildrenById } = storeToRefs(store);

    const toggleAccordion = (): void => {
      if (props.isCollapsedOnMobile) {
        isVisible.value = !isVisible.value;
      }
    };

    return {
      isVisible,
      toggleAccordion,
      getSortedChildrenById,
      getImageData
    };
  },
});
