























import { defineComponent, PropType, toRef } from '@nuxtjs/composition-api';
import type { Country } from '@amplience/types';

export default defineComponent({
  name: 'Countries',
  props: {
    countries: {
      type: Array as PropType<Country[]>,
      default: () => [],
    },
    selected: {
      type: String,
      default: 'United Kingdom',
    },
  },
  setup(props) {
    const selected = toRef(props, 'selected');

    return {
      isSelected: (name: string) => selected.value ? selected.value === name : false,
    }
  }
})
