

















import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';
import { LinkItem } from '@amplience/types';

export default defineComponent({
  name: 'LegalLinks',
  components: {
    SfLink,
  },
  props: {
    legalLinks: {
      type: [Array, null] as PropType<LinkItem[] | null>,
      default: null,
    },
  },
});
