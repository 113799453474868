









































































































import { SfComponentSelect, SfButton }
  from '@storefront-ui/vue';
import {computed, ComputedRef, defineComponent, onMounted, Ref, ref, watch} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import Checkbox from '@theme/components/form/Checkbox.vue';
import FormElement from '@theme/components/form/FormElement.vue';
import PasswordInput from '@theme/components/form/PasswordInput.vue';
import TextField from '@theme/components/form/TextField.vue';
import {useAuth} from '@theme/composables/useAuth';
import {SignUpForm} from '@theme/composables/useAuth/useAuth';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';
import {FormName} from './types';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  components: {
    PasswordInput,
    Checkbox,
    FormElement, TextField,
    SfComponentSelect,
    SfButton,
  },
  props: {
    form: {
      type: Object as PropType<SignUpForm>,
      required: true,
    },
    isFormLoading: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {titles} = useAuth()
    const signUpForm = ref<SignUpForm>({
      email: '',
      firstName: '',
      titleCode: '',
      lastName: '',
      pwd: '',
      checkPwd: '',
      informationByPost: false,
      informationByEmail: false,
    });
    const passwordConfirmation: Ref<string> = ref('');
    const isPasswordTheSame: ComputedRef<boolean> = computed(() =>
      passwordConfirmation.value === signUpForm.value.pwd
      && !!passwordConfirmation.value.length && !!signUpForm.value.pwd.length);

    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    const submitForm = async () => {
      signUpForm.value.checkPwd = signUpForm.value.pwd;

      emit('submit', signUpForm.value);
    };

    onMounted(() => {
      signUpForm.value.titleCode = titles[0];
    });

    watch(() => props.form, (newForm) => { signUpForm.value = { ...newForm }; }, { immediate: true, deep: true });

    return {
      signUpForm,
      passwordConfirmation,
      isPasswordTheSame,
      titles,
      changeForm,
      submitForm,
    };
  },
});
