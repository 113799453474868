
























import { SfLink } from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'ActionItem',
  components: {
    SfLink,
    SvgImage,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Action item',
    },
    routeName: {
      type: String,
      default: 'home',
    },
    qty: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { isAuthenticated } = useUser();
    const getCounterLength = (qty: number) => qty.toString().length;

    return {
      getCounterLength,
      isAuthenticated,
    };
  },
});
