import { computed, onBeforeUnmount, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/bbrTheme/modules/catalog/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isAddToCartNotification: false,
});

export function useUiState(): UseUiStateInterface {
  const toggleAddToCartNotification = () => {
    state.isAddToCartNotification = !state.isAddToCartNotification;
  };

  onBeforeUnmount(() => {
    state.isAddToCartNotification = false;
  })

  return {
    isAddToCartNotification: computed(() => state.isAddToCartNotification),
    toggleAddToCartNotification,
  };
}

export default useUiState;
export * from './useUiState';
