import { Logger } from '~/helpers/logger';
import { computed, readonly, ref, useContext } from '@nuxtjs/composition-api';
import { useCaptcha, useConfig, useUiNotification } from '~/composables';
import {
  ContactUsForm,
  ContactUsFormSupportOptions,
  UseContactUsInterface
} from './useContactUs';

export function useContactUs (): UseContactUsInterface {
  const { getToken, reset } = useCaptcha();
  const { config } = useConfig();
  const { send: sendNotification } = useUiNotification();
  const context = useContext();
  const success = ref<boolean>(false);
  const loading = ref<boolean>(false);
  const supportOptions = computed(() => {
    const options: ContactUsFormSupportOptions = JSON.parse(config.value.contact_help_options);

    return Object.values(options).map(item => item.help_options);
  });

  const send = async (form: ContactUsForm): Promise<void> => {
    try {
      loading.value = true;
      const recaptchaToken = await getToken();

      const contact = { ...form };

      if (contact.phone === '') {
        delete contact.phone;
      }

      const result = await context.app.$vsf.$magento.api.sendContactForm({
        ...contact,
        recaptchaToken,
      });

      if (result.errors) {
        throw new Error(result.errors[0].message);
      }

      await reset();
      success.value = result.data.sendContactUs?.status;
    } catch (error) {
      sendNotification({
        id: Symbol('contact_error'),
        type: 'danger',
        message: context.app.i18n.t(
          'Please try again later or get in touch with us via email, phone or live chat'
        ) as string,
        icon: 'danger',
        persist: false,
        title: context.app.i18n.t(
          'We can not process your enquiry at the moment'
        ) as string,
      });
      Logger.error('useContactUs/send', error);
    } finally {
      loading.value = false;
    }
  }

  return {
    send,
    supportOptions,
    loading: readonly(loading),
    success: readonly(success),
  }
}

export * from './useContactUs';
export default useContactUs;
