































































import {
  ref,
  defineComponent,
  computed,
  useContext, onMounted, useRoute, watch,
} from '@nuxtjs/composition-api';
import {
  SfModal,
  SfBar,
} from '@storefront-ui/vue';
import {useAuth} from '@theme/composables/useAuth';
import {SignUpForm} from '@theme/composables/useAuth/useAuth';
import { useUiState } from '~/composables/useUiState';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useForgotPassword } from '~/modules/customer/composables/useForgotPassword';
import { useUser } from '~/modules/customer/composables/useUser';

import LoginForm from './forms/LoginForm.vue';
import RegisterForm from './forms/RegisterForm.vue';
import ForgotPasswordForm from './forms/ForgotPasswordForm.vue';
import ForgotPasswordThankYou from './forms/ForgotPasswordThankYou.vue';
import {
  ForgotPasswordFormFields, LoginFormFields, RegisterFormFields, FormName,
} from './forms/types';

export default defineComponent({
  name: 'LoginModal',
  components: {
    SfModal,
    SfBar,
    LoginForm,
    RegisterForm,
    ForgotPasswordForm,
    ForgotPasswordThankYou,
  },
  setup(_, { emit }) {
    const { isLoginModalOpen } = useUiState();
    const test = useRoute();
    const isCart = computed<boolean>(() => test.value.path === '/cart');
    const {
      register,
      login,
      loading,
      error: userError,
    } = useUser();

    const { signUp } = useAuth();
    const { load: loadCart } = useCart();
    const { loadItemsCount } = useWishlist();
    const { request: resetPassword, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();

    const currentlyDisplayedForm = ref<FormName>('login');
    const barTitle = computed(() => {
      const mapFormNameToTopBarLabel : Record<FormName, string> = {
        login: 'Sign in',
        register: 'Register',
        forgotPassword: 'Reset Password',
        forgotPasswordThankYou: 'Thank you',
      };

      return mapFormNameToTopBarLabel[currentlyDisplayedForm.value];
    });

    const loginForm = ref<LoginFormFields>({
      email: '',
      password: '',
    });
    const registerForm = ref<SignUpForm>({
      email: '',
      firstName: '',
      titleCode: '',
      lastName: '',
      pwd: '',
      checkPwd: '',
      informationByPost: false,
      informationByEmail: false,
    });
    const forgotPasswordForm = ref<ForgotPasswordFormFields>({
      username: '',
    });

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      loginForm.value = form;
      await login({ user: { ...form } });

      if (!userError.value.login) {
        emit('close');
        await Promise.all([loadItemsCount(), loadCart()]);
      }
    };

    const onRegisterFormSubmit = async (form: SignUpForm) => {
      await signUp(form);

      if (!userError.value.register) {
        emit('close');
        await Promise.all([loadItemsCount(), loadCart()]);
      }
    };

    const onForgotPasswordFormSubmit = async (form: ForgotPasswordFormFields) => {
      forgotPasswordForm.value = form;
      await resetPassword({ email: form.username });
      currentlyDisplayedForm.value = 'forgotPasswordThankYou';
    };

    return {
      isLoginModalOpen,

      userError,
      loading,

      forgotPasswordError,
      forgotPasswordLoading,

      currentlyDisplayedForm,
      barTitle,

      loginForm,
      registerForm,
      forgotPasswordForm,

      onRegisterFormSubmit,
      onLoginFormSubmit,
      onForgotPasswordFormSubmit,
    };
  },
});
