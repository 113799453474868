import { defineStore } from 'pinia';
import type { Wishlist } from '~/modules/GraphQL/types';
import { computed, ref, useRoute } from '@nuxtjs/composition-api';
import {
  sortingOptions,
  SortingOptionsValuesEnum,
} from '~/modules/catalog/category/composables/useFacet/sortingOptions';

export interface URLParams {
  currentPage: number;
  pageSize: number;
  sort?: string;
}

export const useWishlistStore = defineStore('wishlist', () => {
  // Composables
  const route = useRoute();

  // State
  const wishlist = ref<Wishlist>({ items_count: 0 });
  const currentPage = ref<number>(1);
  const pageSize = ref<number>(24);
  const totalItems = ref<number>(0);
  const sorting = ref<string>(null);
  const attributeCodes = ref<string[]>([
    'main_image',
    'family_type',
    'region',
    'maturity',
    'colour',
    'bottle_volume',
    'bottle_order_unit',
    'case_order_unit',
    'style_temp',
    'event_max_tickets',
  ]);

  // Getters
  const totalPages = computed((): number => wishlist.value.items_v2?.page_info?.total_pages || 0);

  const itemsOnPage = computed((): number => {
    return wishlist.value.items_v2?.items?.length === pageSize.value
      ? currentPage.value * pageSize.value
      : totalItems.value;
  });

  const queryParams = computed(() => {
    return { currentPage: currentPage.value, pageSize: pageSize.value };
  });

  const getSortingOptions = computed(() => {
    const options = sortingOptions.filter(
      (option) =>
        option.value !== SortingOptionsValuesEnum.PRICE_ASC && option.value !== SortingOptionsValuesEnum.PRICE_DESC
    );

    return {
      options,
      selected: sorting.value,
    };
  });

  const itemsRange = computed(() => {
    let end = currentPage.value * pageSize.value;
    const start = (end - pageSize.value) + 1;

    if (end > totalItems.value) end = totalItems.value;

    return { start, end };
  });

  // Actions
  const setSorting = (value: string): void => {
    sorting.value = value;
  };

  const setPagination = (): void => {
    currentPage.value = wishlist.value.items_v2?.page_info?.current_page || 1;
    totalItems.value = wishlist.value.items_count || 0;
  };

  const setCurrentPage = (qty: number): void => {
    currentPage.value = qty;
  };

  const setPageSize = async (qty: number): Promise<void> => {
    pageSize.value = qty;
  };

  const setFromQueryParams = (): void => {
    const query = route.value.query;

    if (query.sort) {
      sorting.value = query.sort as string;
    }

    if (query.currentPage) {
      currentPage.value = Number(query.currentPage);
    }

    if (query.pageSize) {
      pageSize.value = Number(query.pageSize);
    }
  };

  return {
    wishlist,
    currentPage,
    pageSize,
    totalItems,
    totalPages,
    itemsOnPage,
    queryParams,
    sorting,
    itemsRange,
    setPagination,
    setCurrentPage,
    setPageSize,
    setSorting,
    getSortingOptions,
    setFromQueryParams,
    attributeCodes,
  };
});
