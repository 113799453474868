



















import type { PropType } from '@nuxtjs/composition-api';
import type { SecurePayment, Logo as LogoInterface } from '@amplience/types';
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { getImageData } from '@amplience/helpers/getImageData';
import Logo from '@amplience/components/atoms/Logo.vue';

export default defineComponent({
  name: 'SecurePayment',
  components: {
    Logo,
  },
  props: {
    config: {
      type: Object as PropType<SecurePayment>,
      required: true,
    },
    defaultLogo: {
      type: Object as PropType<LogoInterface>,
      default: () => ({
        url: '/bbr/lock.svg',
        alt: 'Secure payment',
      }),
    },
  },
  setup(props) {
    const { i18n } = useContext();

    const label = computed<string>(() => (
      props.config.label || i18n.t('Secure payment').toString()
    ));

    return {
      label,
      getImageData,
    };
  },
});
