export const hexColors = {
  'Rosé': '#DA9091',
  'Red': '#5A1D0F',
  'White': '#C2A75D',
};

export const colorIDs = {
  'Rosé': 'rose',
  'Red': 'red',
  'White': 'white',
};
