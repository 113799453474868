/* eslint-disable max-len */
export default {
  'All filters': 'All filters',
  'All Results for `{0}`': 'All Results for `{0}`',
  'View results ({0})': 'View results ({0})',
  'Showing {0} results': 'Showing {0} result | Showing {0} results',
  'Search Results': 'Search Results',
  'No products found': 'No products found',
  'View Product': 'View Product',
  'Demo Category': 'Demo Category',
  'Demo Category - Spirits': 'Demo Category - Spirits',
  'Search for wines or spirits...': 'Search for wines or spirits...',
  'Cancel': 'Cancel',
  'Disabled buying options': 'Disabled buying options',

  // Hits Per Page
  '24 hits per page': '24 hits per page',
  '36 hits per page': '36 hits per page',

  // Sort By
  'Recommended': 'Recommended',
  'Price (Low - High)': 'Price (Low - High)',
  'Price (High - Low)': 'Price (High - Low)',
  'Vintage Ascending': 'Vintage Ascending',
  'Vintage Descending': 'Vintage Descending',
  'Maturity': 'Maturity',
  'Producer A-Z': 'Producer A-Z',
  'Producer Z-A': 'Producer Z-A',
  'Price per ticket (Low - High)': 'Price per ticket (Low - High)',
  'Price per ticket (High - Low)': 'Price per ticket (High - Low)',
  'Event Start Date (Low - High)': 'Event Start Date (Low - High)',
  'Event Start Date (High - Low)': 'Event Start Date (High - Low)',

  // Search box
  'Search suggestions': 'Search suggestions',
  'Product suggestions': 'Product suggestions',
  'Search for popular suggestion: {0}': 'Search for popular suggestion: {0}',
  'Search by product or producer': 'Search by product or producer',
  'Search': 'Search',
  'Search icon': 'Search icon',
  'Search listed wines': 'Search listed wines',
  'Search biddable wines': 'Search biddable wines',
  'We don\'t currently have any results for \“{0}\”': 'We don\'t currently have any results for \“{0}\”',
  'Try different keywords or broaden your search. If you still can\'t find what you\'re looking for, please {contact-us}.': 'Try different keywords or broaden your search. If you still can\'t find what you\'re looking for, please {contact-us}.',

  // Filters
  'No results found': 'No results found',
  'Stock Origin': 'Stock Origin',
  'Spirit Type': 'Spirit Type',
  'Spirit Age': 'Spirit Age',
  'Own Selection': 'Own Selection',
  'Own Selection - Yes': 'Own Selection - Yes',
  'Own Selection - No': 'Own Selection - No',
  'Berrys Wines': 'Berrys Wines',
  'BBX Wines': 'BBX Wines',
  'Colour': 'Colour',
  'Country': 'Country',
  'Regions': 'Regions',
  'Region': 'Region',
  'Sub region': 'Sub region',
  'Grape Variety': 'Grape Variety',
  'Vintage': 'Vintage',
  'Purchase Mode': 'Purchase Mode',
  'Price per Bottle': 'Price per Bottle',
  'Maturity': 'Maturity',
  'Sweetness': 'Sweetness',
  'Case Size': 'Case Size',
  'En Primeur': 'En Primeur',
  'Alcohol Percentage': 'Alcohol Percentage',
  'Price per Case': 'Price per Case',
  'New To BBX': 'New To BBX',
  'New Wines': 'New Wines',
  'Bottle Size': 'Bottle Size',
  'Family Type': 'Family Type',
  'Price (Non-Drinks)': 'Price (Non-Drinks)',
  'Morning before 12:00': 'Morning before 12:00',
  'Afternoon 12-18:00': 'Afternoon 12-18:00',
  'Evening 18:00 and later': 'Evening 18:00 and later',
  'Tickets available': 'Tickets available',
  'Sold out: waitlist only': 'Sold out: waitlist only',
  'Price per ticket': 'Price per ticket',
  'Event Availability': 'Event Availability',
  'Event Start Time': 'Event Start Time',
  'Event Type': 'Event Type',
  'Event Subject': 'Event Subject',
  'Event Month': 'Event Month',
  'Event Weekday': 'Event Weekday',
  'Event Hosts': 'Event Hosts',
  'Event Location': 'Event Location',
};
