







import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/modules/amplience/composables/useContent';

export default defineComponent({
  name: 'AppFooter',
  props: {
    deliveryKey: {
      type: String,
      default: 'global-footer',
    },
  },
  setup(props) {
    const { search, data } = useContent();

    useFetch(async () => {
      await search(props.deliveryKey, 'url');
    });

    return {
      data,
    };
  },
});
