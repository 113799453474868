import { useBuyingOption } from '@theme/modules/catalog/stores/buyingOption';
import {
  getAttributeValue,
  getSellByCaseOnly,
  BBRProductAttributes
} from '@theme/modules/catalog/getters/productGetters';
import { ProductInterface } from '~/modules/GraphQL/types';

interface UseVariantBottleInfo {
  listBottleInfo: () => string;
  gridBottleInfo: () => string;
  productBottleInfo: () => string;
}

export function useVariantBottleInfo(product: ProductInterface | null): UseVariantBottleInfo {
  const { isForDeliverySelected, isInBondSelected } = useBuyingOption();
  const caseOrderUnit = getAttributeValue(product, BBRProductAttributes.CASE_ORDER_UNIT);
  const bottleVolume = getAttributeValue(product, BBRProductAttributes.BOTTLE_VOLUME);
  const bottleOrderUnit = getAttributeValue(product, BBRProductAttributes.BOTTLE_ORDER_UNIT);
  const sellByCaseOnly = getSellByCaseOnly(product);

  /**
   * Get the bottle info based on the buying option for list view
   *
   * @remarks
   *
   * Buying Option "For Delivery" and "In Bond" have the following rules:
   * - "sell by case only" attribute is an integer, typecasted to boolean
   * - "sell by case only" is ignored if "In Bond" is selected
   * - if "sell by case only" is true, add "Case" to the label for "For Delivery" buying option
   * - if "sell by case only" is false, do not add "Case" to the label for "For Delivery" buying option
   * - if "In Bond" is selected, always display caseOrderUnit
   *
   * Example #1
   * - "buying_option" = "default"
   * - "sell_by_case_only" = true
   * - "case_order_unit" = 6;
   * - "bottle_volume" = 75;
   * - "bottle_order_unit" = "cl",
   * Result: 6 x 75cl - Case
   *
   * Example #2
   * - "buying_option" = "default"
   * - "sell_by_case_only" = false
   * - "case_order_unit" = 1;
   * - "bottle_volume" = 75;
   * - "bottle_order_unit" = "cl",
   * Result: 1 x 75cl
   *
   * Example #3
   * - "buying_option" = "inbond"
   * - "sell_by_case_only" = true
   * - "case_order_unit" = 6;
   * - "bottle_volume" = 75;
   * - "bottle_order_unit" = "cl",
   * Result: 6 x 75cl - Case
   */
  const listBottleInfo = () => {
    if (!product) {
      return '';
    }

    const bottleInfo = `${bottleVolume} ${bottleOrderUnit.toLowerCase()}`;
    const caseInfo = `${caseOrderUnit} x ${bottleInfo}`;

    if (isForDeliverySelected) {
      return sellByCaseOnly ?
        `${caseInfo} - Case` :
        `1 x ${bottleInfo}`;
    } else if (isInBondSelected) {
      // "Sell by case only" is ignored if "In Bond" is selected
      return `${caseInfo}`;
    }
  };

  // Get the bottle info based on the buying option for grid view
  const gridBottleInfo = () => {
    if (!product) {
      return '';
    }

    const bottleInfo = `${bottleOrderUnit.toLowerCase()} (${bottleVolume})`;
    const caseInfo = `${caseOrderUnit} x ${bottleVolume}`;

    if (isForDeliverySelected) {
      return sellByCaseOnly ? `case (${caseInfo})` : `${bottleInfo}`;
    } else if (isInBondSelected) {
      // "Sell by case only" is ignored if "In Bond" is selected
      return `case (${caseInfo})`;
    }
  };

  // Get the bottle info based on the buying option for product page
  const productBottleInfo = () => {
    if (!product) {
      return '';
    }

    const bottleInfo = `${bottleVolume}`;
    const caseInfo = `${caseOrderUnit} x ${bottleInfo}`;

    if (isForDeliverySelected) {
      return sellByCaseOnly ?
        `Case - ${caseInfo}` :
        `Bottle - 1 x ${bottleInfo}`;
    } else if (isInBondSelected) {
      // "Sell by case only" is ignored if "In Bond" is selected
      return `Case - ${caseInfo}`;
    }
  };

  return {
    listBottleInfo,
    gridBottleInfo,
    productBottleInfo,
  }
}

export default useVariantBottleInfo;
