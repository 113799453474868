




















import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { Drinkaware } from '@amplience/types';
import { SfLink } from '@storefront-ui/vue';
import Logo from '@amplience/components/atoms/Logo.vue';

export default defineComponent({
  name: 'Copyright',
  components: {
    SfLink,
    Logo,
  },
  props: {
    isGlobalFooter: {
      type: Boolean,
      default: false,
    },
    copyright: {
      type: String,
      default: '',
    },
    drinkaware: {
      type: [Object, null] as PropType<Drinkaware | null>,
      default: null,
    }
  },
  setup(props) {
    const defaultLogo = computed(() => {
      return {
        url: props.isGlobalFooter ? '/bbr/drinkaware/white.svg' : '/bbr/drinkaware/black.svg',
        alt: 'Footer Logo',
      }
    });

    return {
      defaultLogo
    }
  }
});
