







// Ensures a 44x44 tap target for touch devices.
// Actual rendered element doesn't change in size,
// this just expands the hit area in a totally invisible way that doesn't impact the layout
// Example: <button> <TouchTarget /> </button>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TouchTarget',
});
