










import { defineComponent } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import { useNavigation } from '@amplience/stores/navigation';

export default defineComponent({
  name: 'ActionItemMenu',
  components: {
    SvgImage,
  },
  setup() {
    const nav = useNavigation();

    return {
      toggleNavigation: nav.openModalNavigation,
    }
  }
});
