import { ImageInterface, MappedImage } from '@amplience/types';
import { extractImage } from '@vsf-enterprise/amplience';

export const getImageData = (image: ImageInterface): MappedImage  => {
  const img = extractImage(image);

  // Get static image URL for SVG files
  if (!image.mimeType || image.mimeType === 'image/svg+xml') {
    img.url = `${process.env.VSF_AMPLIENCE_MEDIA_STATIC_URL}v1/static/${image.name}`;
  }

  return img;
}
