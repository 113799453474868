import { Middleware } from '@nuxt/types';
import { isDualRunning, isLoggedIn } from '~/bbrTheme/helpers/dualRunning';

const middleware : Middleware = (context) => {
  if (isDualRunning(context.app.$cookies)) {
    if (!isLoggedIn(context.app.$cookies)) {
      context.redirect('/');
    }
  } else if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
    context.redirect('/auth/redirect', {
      type: 'login',
    });
  }
};

export default middleware;
