import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';

export const useCellarUiStore = defineStore('cellar-ui', () => {
  // State
  const lastActiveItemId = ref<string | null>(null);
  const isOpenedFromHistory = ref<boolean>(false);
  const queries = ref<any>({});

  // Actions
  const setLastActiveItemId = (value: string): void => {
    lastActiveItemId.value = value;
  };

  const setQueries = (value: any): void => {
    queries.value = {...queries.value, ...value};
  };

  const clearQueries = (): void => {
    queries.value = {};
  }

  const deleteQuery = (attribute: string) => {
    delete queries.value[attribute];
  }

  const setIsOpenedFromHistory = (value: boolean): void => {
    isOpenedFromHistory.value = value;
  };

  return {
    lastActiveItemId,
    isOpenedFromHistory,
    queries,
    setQueries,
    deleteQuery,
    clearQueries,
    setLastActiveItemId,
    setIsOpenedFromHistory,
  };
});
