import { computed, reactive, useContext } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isQuickAccessModalOpen: false,
  isAccountSwitcherModalOpen: false,
});

enum ProductsViewMode {
  List = 'list',
  Grid = 'grid',
}

type ProductsViewModeType = `${ProductsViewMode}`;

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const { app } = useContext();
  const apiState = app.context.$vsf.$magento.config.state;

  const selectedModeView = computed(() => {
    let modeView = apiState.getViewMode() as ProductsViewModeType;
    modeView = modeView || ProductsViewMode.Grid;

    return modeView;
  });

  state.isCategoryGridView = selectedModeView.value === ProductsViewMode.Grid;

  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
    apiState.setViewMode(ProductsViewMode.Grid);
  };

  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
    apiState.setViewMode(ProductsViewMode.List);
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };

  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const openQuickAccessModal = () => {
    state.isQuickAccessModalOpen = true;
  };

  const closeQuickAccessModal = () => {
    state.isQuickAccessModalOpen = false;
  };

  const openAccountSwitcherModal = () => {
    state.isQuickAccessModalOpen = false;
    state.isAccountSwitcherModalOpen = true;
  };

  const closeAccountSwitcherModal = () => {
    state.isAccountSwitcherModalOpen = false;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isQuickAccessModalOpen: computed(() => state.isQuickAccessModalOpen),
    isAccountSwitcherModalOpen: computed(() => state.isAccountSwitcherModalOpen),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    openQuickAccessModal,
    closeQuickAccessModal,
    openAccountSwitcherModal,
    closeAccountSwitcherModal,
  };
}

export default useUiState;
export * from './useUiState';
