import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import type {
  ValuationInfo,
  MaturityStatus,
  MarketPriceValuationInfo,
  MarketPriceGuidance,
  ValuationDefinitions,
  ArrangeDeliveryNote,
} from '@amplience/types';

export const useCellarContentStore = defineStore('cellar-content', () => {
  // State
  const valuationInfo = ref<ValuationInfo>(null);
  const maturityStatus = ref<MaturityStatus>(null);
  const marketPriceValuationInfo = ref<MarketPriceValuationInfo>(null);
  const marketPriceGuidance = ref<MarketPriceGuidance>(null);
  const valuationDefinitions = ref<ValuationDefinitions>(null);
  const arrangeDeliveryNote = ref<ArrangeDeliveryNote>(null);

  // Actions
  const setValuationInfo = (value: ValuationInfo) => (valuationInfo.value = value);
  const setMaturityStatus = (value: MaturityStatus) => (maturityStatus.value = value);
  const setMarketPriceValuationInfo = (value: MarketPriceValuationInfo) => (marketPriceValuationInfo.value = value);
  const setMarketPriceGuidance = (value: MarketPriceGuidance) => (marketPriceGuidance.value = value);
  const setValuationDefinitions = (value: ValuationDefinitions) => (valuationDefinitions.value = value);
  const setArrangeDeliveryNote = (value: ArrangeDeliveryNote) => (arrangeDeliveryNote.value = value);

  return {
    valuationInfo,
    setValuationInfo,
    maturityStatus,
    setMaturityStatus,
    marketPriceValuationInfo,
    setMarketPriceValuationInfo,
    marketPriceGuidance,
    setMarketPriceGuidance,
    valuationDefinitions,
    setValuationDefinitions,
    arrangeDeliveryNote,
    setArrangeDeliveryNote,
  };
});
