import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=fc54aff0"
import script from "./AppFooter.vue?vue&type=script&lang=ts"
export * from "./AppFooter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RenderContent: require('/var/www/modules/amplience/components/render/Content.vue').default})
