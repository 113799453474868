
















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderNavigationChild',
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: String,
      default: '',
    },
  },
})
