





























































































import { computed, defineComponent, PropType, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api';
import { SfBar, SfButton } from '@storefront-ui/vue';
import Modal from '@theme/components/organisms/Modal.vue';
import Parent from '@amplience/components/atoms/navigation/Parent.vue';
import Child from '@amplience/components/atoms/navigation/Child.vue';
import Group from '@amplience/components/atoms/navigation/Group.vue';
import { useNavigation } from '@amplience/stores/navigation';
import { storeToRefs } from 'pinia';
import type { Country, NavigationsInterface } from '@amplience/types';
import CountrySelector from '@amplience/components/molecules/header/CountrySelector.vue';

export default defineComponent({
  name: 'TabletMobileNavigation',
  components: {
    SfBar,
    SfButton,
    Modal,
    Parent,
    Child,
    Group,
    CountrySelector,
  },
  props: {
    navigations: {
      type: Array as PropType<NavigationsInterface[]>,
      required: true,
    },
    countries: {
      type: [Array, null] as PropType<Country[]>,
      default: () => [],
    },
  },
  setup() {
    const nav = useNavigation();
    const { i18n } = useContext();
    const route = useRoute();
    const path = computed(() => route.value.path);
    const {
      isModalOpened,
      getSortedChildrenById,
      getNavigationById,
      getParentByGroupId,
      currentCountry,
    } = storeToRefs(nav);
    const activeCategoryId = ref<string | null>(null);
    const isParentLevel = computed<boolean>(() => activeCategoryId.value === null);
    const isGroupLevel = ref<boolean>(false);
    const isChildLevel = ref<boolean>(false);
    const isCountrySelectorLevel = ref<boolean>(false);
    const currentPath = computed<NavigationsInterface>(() => getNavigationById.value(activeCategoryId.value));
    const backButtonLabel = computed<string>(() => {
      if (isGroupLevel.value || isCountrySelectorLevel.value) {
        return i18n.t('Main menu') as string;
      }

      if (isChildLevel.value) {
        const parent = getParentByGroupId.value(activeCategoryId.value);

        return parent?.title || '';
      }

      return '';
    });

    // Close modal upon navigation to a different page
    watch(path, () => {
      handleClose();
    })

    const handleGroupLevel = (id: string, isEmpty: boolean): void => {
      if (!isEmpty) {
        activeCategoryId.value = id;
        isGroupLevel.value = true;
        isChildLevel.value = false;
      }
    }

    const handleChildLevel = (id: string): void => {
      activeCategoryId.value = id;
      isGroupLevel.value = false;
      isChildLevel.value = true;
    }

    const handleCountrySelectorLevel = (): void => {
      activeCategoryId.value = 'country-selector';
      isCountrySelectorLevel.value = true;
    }

    const handleBack = (): void => {
      if (!currentPath.value?.parentId && isCountrySelectorLevel.value) {
        activeCategoryId.value = null;
        isCountrySelectorLevel.value = false;
      }

      if (isChildLevel.value) {
        handleGroupLevel(currentPath.value.parentId, false);
      } else {
        activeCategoryId.value = null;
        isGroupLevel.value = false;
      }
    }

    const handleClose = (): void => {
      nav.closeModalNavigation();
      activeCategoryId.value = null;
      isGroupLevel.value = false;
      isChildLevel.value = false;
      isCountrySelectorLevel.value = false;
    }

    return {
      activeCategoryId,
      isModalOpened,
      isParentLevel,
      isGroupLevel,
      isChildLevel,
      isCountrySelectorLevel,
      getSortedChildrenById,
      currentPath,
      backButtonLabel,
      currentCountry,
      handleBack,
      handleClose,
      handleGroupLevel,
      handleChildLevel,
      handleCountrySelectorLevel,
    }
  }
})
