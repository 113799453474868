import { Plugin } from '@nuxt/types';

interface FormatPercent {
  $fpcent(value: number | string): string;
  $fpcent(value: number | string, options?: Intl.NumberFormatOptions): string;
  $fpcent(value: number | string, locale?: string, options?: Intl.NumberFormatOptions): string;
}

declare module 'vue/types/vue' {
  interface Vue extends FormatPercent {}
}

declare module '@nuxt/types' {
  interface Context extends FormatPercent {}
}

const DEFAULT_OPTIONS = {
  style: 'percent',
  maximumFractionDigits: 4,
};

const plugin: Plugin = (context, inject) => {
  inject('fpcent', (value: number | string, locale?: string, options = DEFAULT_OPTIONS): string => {
    const currentLocale = (locale || context.i18n?.localeProperties?.iso || '').replace('_', '-');

    return new Intl.NumberFormat(currentLocale, options).format(Number(value) / 100);
  });
};

export default plugin;
