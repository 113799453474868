































import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import { AccordionItemContentInterface } from '@amplience/types';

export default defineComponent({
  name: 'Accordion',
  components: {
    SfHeading,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: Array as PropType<AccordionItemContentInterface[]>,
      default: () => [],
    }
  },
  setup() {
    const activeItem = ref<number | null>(null);
    const handleAccordionActiveItem = (idx: number) => activeItem.value === idx
      ? activeItem.value = null
      : activeItem.value = idx;

    return {
      activeItem,
      handleAccordionActiveItem,
    };
  },
})
