/** GraphQL Fragment that contains the shopping cart display settings */
// FIXME: Data is not available in the GraphQL API
// https://github.com/magento/magento2/tree/2.4.6-p5/app/code/Magento/TaxGraphQl
export const fragmentShoppingCartDisplaySettings = `
  fragment ShoppingCartDisplaySettings on StoreConfig {
    shopping_cart_display_full_summary
    shopping_cart_display_grand_total
    shopping_cart_display_price
    shopping_cart_display_shipping
    shopping_cart_display_subtotal
    shopping_cart_display_zero_tax
    shopping_cart_display_tax_gift_wrapping
  }
`;

/** GraphQL Query that fetches store configuration from the API */
export const StoreConfigQuery = `
  query storeConfig {
    storeConfig {
      show_cart_id
      store_code
      default_title
      store_name
      default_display_currency_code
      locale
      header_logo_src
      logo_width
      logo_height
      logo_alt
      is_allowed_guest_checkout
      cs_market_price_guidance
      en_primeur_notification_title
      en_primeur_notification_description
      contact_help_options
      magento_wishlist_general_is_enabled
    }
  }
`;

export default StoreConfigQuery;
