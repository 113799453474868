

























































































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import cartGetters from '~/modules/checkout/getters/cartGetters';

export default defineComponent({
  name: 'Cart',
  setup() {
    const cartView = useCartView();
    const isVisible = ref<boolean>(false);

    return {
      ...cartView,
      cartGetters,
      isVisible,
    };
  },
});
