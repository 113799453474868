import {
  fragmentCustomerDetails,
  fragmentCustomerStoreCredit,
  fragmentCustomerAddresses,
} from './fragments'

export default `
  mutation updateBbrCustomerPassword($password: String!) {
    updateBbrCustomerPassword(password: $password) {
      customer {
        ...CustomerDetails
        ...CustomerStoreCredit
        ...CustomerAddresses  
      }
    }
  }
  ${fragmentCustomerDetails}
  ${fragmentCustomerStoreCredit}
  ${fragmentCustomerAddresses}
`;
