











import { defineComponent, PropType } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'USPIcon',
  props: {
    icon: {
      type: String,
      default: '',
    },
    alignItems: {
      type: String as PropType<'center' | 'start' | 'end'>,
      default: 'center',
    },
  },
});
