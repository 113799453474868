import {
  fragmentCustomerAddresses,
  fragmentCustomerDetails,
  fragmentCustomerStoreCredit,
} from '@theme/customQueries/fragments';

export default `
  mutation updateBbrCustomerEmail($email: String!) {
    updateBbrCustomerEmail(email: $email) {
      customer {
        ...CustomerDetails
        ...CustomerStoreCredit
        ...CustomerAddresses  
      }
    }
  }
  ${fragmentCustomerDetails}
  ${fragmentCustomerStoreCredit}
  ${fragmentCustomerAddresses}
`;
