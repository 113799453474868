












import {
  defineComponent,
  PropType,
  ref,
  useContext,
  useFetch,
} from '@nuxtjs/composition-api';
import type { Country, NavigationInterface, NavigationsInterface } from '@amplience/types';
import { useNavigation } from '@amplience/stores/navigation';
import { storeToRefs } from 'pinia';
import DesktopMegaMenu from '@amplience/components/organisms/mega-menu/DesktopMegaMenu.vue';
import TabletMobileMegaMenu from '@amplience/components/organisms/mega-menu/TabletMobileMegaMenu.vue';

export default defineComponent({
  name: 'GlobalHeaderNavigation',
  components: {
    DesktopMegaMenu,
    TabletMobileMegaMenu,
  },
  props: {
    navigation: {
      type: Object as PropType<NavigationInterface>,
      required: true,
    },
    countries: {
      type: [Array, null] as PropType<Country[]>,
      default: () => [],
    },
  },
  setup(props) {
    const context = useContext();
    const nav = useNavigation();
    const navigations = ref<NavigationsInterface[]>([]);
    const { getSortedChildrenById } = storeToRefs(nav);

    useFetch( async () => {
      await nav.fetchNodes(context);
      navigations.value = getSortedChildrenById.value(props.navigation?._meta?.deliveryId);
    });

    return {
      navigations,
      getSortedChildrenById,
    }
  }
})
