






import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '@amplience/composables/useContent';
import SimpleHeader from '@amplience/components/content-type/SimpleHeader.vue';

export default defineComponent({
  name: 'HeaderSlim',
  components: {
    SimpleHeader,
  },
  props: {
    deliveryKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { search, data } = useContent();

    useFetch(async () => {
      await search(props.deliveryKey, 'url');
    });

    return {
      data,
    };
  },
});
