import { defineStore } from 'pinia';
import { useSearch } from '~/modules/amplience/composables/useSearch';
import type { NavigationsInterface, NavigationStoreInterface } from '~/modules/amplience/types';

export const useNavigation = defineStore('navigation', {
  state: () => <NavigationStoreInterface>({
    navigations: [],
    isModalOpened: false,
    currentCountry: 'United Kingdom',
  }),
  getters: {
    getChildrenById(state: NavigationStoreInterface): Function {
      return (parentId: string): NavigationsInterface[] => {
        return state.navigations.filter((navigation) => navigation.parentId === parentId);
      };
    },
    getSortedChildrenById(): Function {
      return (parentId: string): NavigationsInterface[] => {
        return this.getChildrenById(parentId)
          .sort((a: NavigationsInterface, b: NavigationsInterface) => Number(a.sortOrder) - Number(b.sortOrder));
      };
    },
    getNavigationById(state: NavigationStoreInterface): Function {
      return (id: string): NavigationsInterface | null => {
        return state.navigations.find(nav => nav.deliveryId === id) || null;
      };
    },
    getParentByGroupId(): Function {
      return (id: string): NavigationsInterface | null => {
        const parent = this.getNavigationById(id);

        return parent ? this.getNavigationById(parent.parentId) : null;
      };
    },
    getParentByUrl(state: NavigationStoreInterface): Function {
      return (url: string): NavigationsInterface | null => {
        const item = state.navigations.find(navigation => {
          return navigation.url === url;
        }) || null;

        return item ? this.getParentByGroupId(item.parentId) : null;
      }
    }
  },
  actions: {
    async fetchNodes(ctx) {
      if (!this.navigations.length) {
        const { getData } = useSearch(ctx);
        const data = await getData({
          indexName: 'navigations',
          params: {
            hitsPerPage: 1000,
          },
        });
        this.navigations = data?.items ?? [];
      }
    },
    openModalNavigation() {
      this.isModalOpened = true;
    },
    closeModalNavigation() {
      this.isModalOpened = false;
    },
    setCurrentCountry(country: string) {
      this.currentCountry = country;
    }
  },
});
