import { Middleware } from '@nuxt/types';
import { isDualRunning } from '~/bbrTheme/helpers/dualRunning';
import { useConfigStore } from '~/stores/config';

const middleware : Middleware = (context) => {
  const configStore = useConfigStore();

  if (isDualRunning(context.app.$cookies)) {
    return;
  }

  if (
    !configStore.storeConfig.is_allowed_guest_checkout &&
    !context.app.$vsf.$magento.config.state.getCustomerToken()
  ) {
    context.redirect('/auth/redirect', {
      type: 'checkout',
    });
  }
};

export default middleware;
