









import { defineComponent } from '@nuxtjs/composition-api';
import Account from '@theme/components/global-header/action-items/Account.vue';
import Cart from '@theme/components/global-header/action-items/Cart.vue';
import Search from '@theme/components/global-header/action-items/Search.vue';
import Wishlist from '@theme/components/global-header/action-items/Wishlist.vue';
import useMagentoConfiguration from '~/composables/useMagentoConfiguration';

export default defineComponent({
  name: 'ActionItemGroup',
  components: {
    Account,
    Cart,
    Search,
    Wishlist,
  },
  setup() {
    const { isWishlistEnabled } = useMagentoConfiguration();

    return {
      isWishlistEnabled,
    }
  },
});
