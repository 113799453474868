//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Imported from node_modules/@storefront-ui/vue/src/components/molecules/SfCheckbox/SfCheckbox.vue
export default {
  name: 'Checkbox',
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    hintMessage: {
      type: String,
      default: 'Required.',
    },
    required: {
      type: Boolean,
      default: false,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Array, Boolean],
      default: () => [],
    },
    isMiddleState: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isChecked() {
      if (typeof this.selected === 'boolean') {
        return this.selected;
      } else {
        return this.selected.includes(this.value);
      }
    },
  },
  methods: {
    inputHandler() {
      if (typeof this.selected === 'boolean') {
        this.$emit('change', !this.selected);
      } else {
        let selected = [...this.selected];

        if (selected.includes(this.value)) {
          selected = selected.filter((value) => value !== this.value);
        } else {
          selected.push(this.value);
        }

        this.$emit('change', selected);
      }
    },
  },
};
