import { ref } from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import searchCategoriesGql from './searchCategories.gql';
import type { CategoryTree } from '~/modules/GraphQL/types';

type CachedCategories = {
  [key: string]: CategoryTree[];
}

export const useSearchForCategories = (isDynamicSearch = false) => {
  const cache = ref<CachedCategories>({});
  const { query } = useApi();
  const categoriesSearchList = ref<CategoryTree[]>([]);
  const searchResult = ref<CategoryTree[]>([]);

  const normalizeString = (str: string): string => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  const generateSearchResults = (input: string, categories: CategoryTree[]) => {
    const normalizedInput = normalizeString(input.toLowerCase());

    categoriesSearchList.value = categories.map((category: CategoryTree) => {
      const normalizedCategoryName = normalizeString(category.name.toLowerCase());

      if (isDynamicSearch && normalizedCategoryName.includes(normalizedInput)) {
        return category;
      }

      return {
        ...category,
        children: category.children.filter((child: CategoryTree) => {
          const normalizedChildName = normalizeString(child.name.toLowerCase());

          return normalizedChildName.includes(normalizedInput);
        })
      };
    }).filter((category: CategoryTree) => category.children.length > 0);

    searchResult.value = categoriesSearchList.value.flatMap((category: CategoryTree) => category.children);
  };

  const search = async (input: string, categories: CategoryTree[], filters = {}) => {
    generateSearchResults(input, categories);

    if (isDynamicSearch) {
      if (!cache.value[input] && input.length > 1) {
        const { data } = await query<{ categories: { items: CategoryTree[] } }>(searchCategoriesGql, {
          name: input,
          ...filters,
        });

        cache.value[input] = data?.categories?.items || [];
      }

      searchResult.value = cache.value[input] || [];
    }
  };

  return {
    categoriesSearchList,
    search,
    searchResult,
    generateSearchResults,
  };
}

export default useSearchForCategories;
