import { render, staticRenderFns } from "./GlobalFooter.vue?vue&type=template&id=591c1269&scoped=true"
import script from "./GlobalFooter.vue?vue&type=script&lang=ts"
export * from "./GlobalFooter.vue?vue&type=script&lang=ts"
import style0 from "./GlobalFooter.vue?vue&type=style&index=0&id=591c1269&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "591c1269",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Accordion: require('/var/www/modules/amplience/components/content-type/Accordion.vue').default,SocialIcons: require('/var/www/modules/amplience/components/content-type/SocialIcons.vue').default})
