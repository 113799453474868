











import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderNavigationGroup',
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
})
