import { computed, ref } from '@nuxtjs/composition-api';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export const bbxStore = () => {
  const customerStore = useCustomerStore();

  // State
  const isLoading = ref(false); // both asynchronous & synchronous loading
  const isBlockingLoading = ref(false); // synchronous loading
  const activeRows = ref<string[]>([]);

  // Getters
  const uniqueID = computed(() => customerStore.sapUniqueID);
  const defaultID = computed(() => customerStore.sapDefaultID);
  const linkedAccounts = computed(() => customerStore.sapLinkedAccounts);

  // Actions
  const setIsBlockingLoading = (value) => (isBlockingLoading.value = value);
  const resetActiveRows = () => activeRows.value = [];

  const toggleRow = (row: string) => {
    const index = activeRows.value.indexOf(row);

    if (index === -1) {
      activeRows.value.push(row);
    } else {
      activeRows.value.splice(index, 1);
    }
  };

  const closeRow = (row: string) => activeRows.value.splice(activeRows.value.indexOf(row), 1);

  const setIsLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const resetStore = () => {
    setIsLoading(false);
    setIsBlockingLoading(false);
    resetActiveRows();
  }

  return {
    isLoading,
    isBlockingLoading,
    uniqueID,
    defaultID,
    linkedAccounts,
    setIsBlockingLoading,
    setIsLoading,
    activeRows,
    resetActiveRows,
    toggleRow,
    closeRow,
    resetStore,
  };
};
