

























































import {
  defineComponent,
  useFetch
} from '@nuxtjs/composition-api';
import Modal from '~/bbrTheme/components/organisms/Modal.vue';
import { useCellarContentStore } from '@cellar-services/stores/cellar-content';
import { storeToRefs } from 'pinia';
import { useContent, CELLAR_MATURITY_STATUS } from '@amplience/composables/useContent';

export default defineComponent({
  name: 'MaturityStatusSidebar',
  components: {
    Modal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const cellarContent = useCellarContentStore();
    const { search, data: maturityContent } = useContent();

    const { maturityStatus } = storeToRefs(cellarContent);

    useFetch(async () => {
      if (!maturityStatus.value) {
        await search(CELLAR_MATURITY_STATUS, 'url');
        cellarContent.setMaturityStatus(maturityContent.value);
      }
    });

    return {
      maturityStatus,
    };
  },
});
