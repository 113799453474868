


























import {
  PropType,
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Notification',
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<'info' | 'success' | 'warning' | 'danger'>,
      default: 'info',
    },
  },
});
