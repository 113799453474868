import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import type { UiNotification } from '~/composables/useUiNotification';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { isDualRunning, isLoggedIn, setIsLoggedIn } from '~/bbrTheme/helpers/dualRunning';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin : Plugin = ({ $pinia, app }) => {
  const customerStore = useCustomerStore($pinia);

  if (isDualRunning(app.$cookies)) {
    /** TODO: handle graphql auth errors
    app.$vsf.$magento.client.interceptors.response.use((res) => {
      if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
        return res;
      }
      //TODO: what should happen when we get auth errors from Magento?
      return false;
    });
    */

    app.$vsf.$hybris.client.interceptors.response.use((res) => {
      const userUid = res?.data?.data?.cartData?.user?.uid;

      if (userUid) {
        if (userUid === 'anonymous') {
          app.$cookies.remove(app.$vsf.$cellarServices.config.cookieNames.algoliaUserApiKey);
        }

        setIsLoggedIn(app.$cookies, userUid !== 'anonymous');
        customerStore.setIsLoggedIn(userUid !== 'anonymous');
      }

      return res;
    });

    return;
  }

  if (app.$vsf.$magento.config.state.getCustomerToken()) {
    console.log('[setIsLoggedIn] Magento Customer login.');
    customerStore.setIsLoggedIn(true);
  }

  app.$vsf.$magento.client.interceptors.response.use((res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
      return res;
    }

    customerStore.setIsLoggedIn(false);
    app.$vsf.$magento.config.state.removeCustomerToken();
    app.$vsf.$magento.config.state.removeCartId();
    app.$vsf.$magento.config.state.setMessage<UiNotification>({
      id: Symbol(''),
      message: app.i18n.t('You are not authorized, please log in.') as string,
      type: 'warning',
      icon: null,
      persist: true,
      title: null,
    });

    return false;
  });
};

export default plugin;
