export const fragmentPriceRangeFields = `
  fragment PriceRangeFields on PriceRange {
    maximum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
  }
`;

export const fragmentSimpleProductStockData = `
  fragment SimpleProductStockDataFields on SimpleProduct {
    stock_data {
      case_qty
      qty
    }
  }
`;

// Price per case, dependent on the selected buying option
export const fragmentSimpleProductCustomPrices = `
  fragment SimpleProductCustomPricesFields on SimpleProduct {
    custom_prices {
      price_value {
        amount {
          currency
          value
        }
      }
      price_per_case {
        amount {
          currency
          value
        }
      }
    }
  }
`;

export const fragmentProductInterfaceListingsFields = `
  fragment ProductInterfaceListingsFields on ProductInterface {
    listing_type
    item_name
    listing_id
    ext_listing_id
  }
`;

export const fragmentConfigurableProductHasVariants = `
  fragment ConfigurableProductHasVariants on ConfigurableProduct {
    is_more_variant_available
  }
`;

export const fragmentProductInterfaceLabels = `
  fragment ProductInterfaceLabels on ProductInterface {
    product_labels {
      code
      label
      sort_order
    }
    labels {
      code
      label
      sort_order
    }
  }
`;

export const fragmentCartProductFields = `
  fragment CartProductFields on ProductInterface {
    uid
    __typename
    sku
    name
    stock_status
    only_x_left_in_stock
    rating_summary
    attributes_value(attribute_codes: [
      "main_image",
      "family_type",
      "bottle_order_unit",
      "bottle_volume",
      "case_order_unit",
      "en_primeur"
    ]) {
      code
      label
      value
    }
    thumbnail {
      url
      position
      disabled
      label
    }
    url_key
    url_rewrites {
      url
    }
    price_range {
      maximum_price {
        final_price {
          currency
          value
        }
        regular_price {
          currency
          value
        }
      }
      minimum_price {
        final_price {
          currency
          value
        }
        regular_price {
          currency
          value
        }
      }
    }
    categories {
      uid
      name
      url_suffix
      url_path
      breadcrumbs {
        category_name
        category_url_path
      }
    }
    review_count
    reviews {
      items {
        average_rating
        ratings_breakdown {
          name
          value
        }
      }
    }
  }
`;

export const fragmentBbrCartItem = `
  fragment BbrCartItem on CartItemInterface {
    uid
    quantity
    product {
      ...CartProductFields
    }
    prices {
      price {
        value
      }
      row_total {
        value
      }
      row_total_including_tax {
        value
      }
      total_item_discount {
        value
      }
    }
    ... on ConfigurableCartItem {
      configurable_options {
        configurable_product_option_uid
        attribute_code
        option_label
        configurable_product_option_value_uid
        value_label
      }
      configured_variant {
        sell_by_case_only
        ...SimpleProductCustomPricesFields
        ...SimpleProductStockDataFields
        ...ProductInterfaceListingsFields
        attributes_value(attribute_codes: [
          "case_order_unit"
        ]) {
          code
          label
          value
        }
        sku
        uid
        thumbnail {
          url
        }
      }
    }
    ... on BundleCartItem {
      bundle_options {
        uid
        label
        type
        values {
          id
          label
          price
          quantity
        }
      }
    }
  }
  ${fragmentCartProductFields}
  ${fragmentSimpleProductStockData}
  ${fragmentSimpleProductCustomPrices}
  ${fragmentProductInterfaceListingsFields}
`;

export const fragmentCustomerDetails = `
  fragment CustomerDetails on Customer {
    date_of_birth
    default_billing
    default_shipping
    email
    firstname
    is_subscribed
    lastname
    middlename
    prefix
    suffix
    taxvat
    custom_attributes {
      code
      value
    }
  }
`;

export const fragmentCustomerStoreCredit = `
  fragment CustomerStoreCredit on Customer {
    store_credit {
      enabled
      current_balance {
        currency
        value
      }
    }
  }
`;

export const fragmentCustomerAddresses = `
  fragment CustomerAddresses on Customer {
    addresses {
      city
      country_code
      default_billing
      default_shipping
      custom_attributes {
        attribute_code
        value
      }
      firstname
      id
      lastname
      postcode
      prefix
      region {
        region_code
        region_id
        region
      }
      street
      suffix
      telephone
      vat_id
    }
  }
`;

export const fragmentShippingCartAddressCustomAttributes = `
  fragment ShippingCartAddressCustomAttributes on ShippingCartAddress {
    alt_telephone
    address_name
  }
`;

export default {
  fragmentProductInterfaceLabels,
  fragmentPriceRangeFields,
  fragmentSimpleProductStockData,
  fragmentSimpleProductCustomPrices,
  fragmentProductInterfaceListingsFields,
  fragmentConfigurableProductHasVariants,
  fragmentCartProductFields,
  fragmentBbrCartItem,
  fragmentCustomerDetails,
  fragmentCustomerStoreCredit,
  fragmentCustomerAddresses,
  fragmentShippingCartAddressCustomAttributes,
};
