import {
  fragmentProductInterfaceLabels,
  fragmentSimpleProductCustomPrices,
  fragmentSimpleProductStockData,
  fragmentPriceRangeFields,
  fragmentProductInterfaceListingsFields,
  fragmentConfigurableProductHasVariants,
} from '~/bbrTheme/customQueries/fragments';

/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData(
    $search: String = "",
    $filter: ProductAttributeFilterInput,
    $pageSize: Int = 24,
    $currentPage: Int = 1,
    $sort: ProductAttributeSortInput,
    $filteredVariants: Boolean = false,
    $buyingOption: BuyingOptionEnum = DEFAULT,
    $attributeCodes: [String!],
  ) {
    products(
      search: $search,
      filter: $filter,
      pageSize: $pageSize,
      currentPage: $currentPage,
      sort: $sort,
      buying_option: $buyingOption,
      attribute_codes: $attributeCodes
    ) {
      items {
        __typename
        uid
        sku
        name
        stock_status
        url_key
        is_in_wishlist
        wishlist_item_id
        roundel_url_code
        ...SimpleProductCustomPricesFields
        ...SimpleProductStockDataFields
        ...ProductInterfaceLabels
        attributes_value(attribute_codes: $attributeCodes) {
          code
          label
          value
        }
        url_rewrites {
          url
        }
        price_range {
          ...PriceRangeFields
        }
        rating_summary
        review_count
        reviews {
          items {
            nickname
            score
            average_rating
            ratings_breakdown {
              name
              value
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
          }
          ...ConfigurableProductHasVariants
          variants(filtered_variants: $filteredVariants) {
            __typename
            product {
              stock_status
              name
              sku
              uid
              sell_by_case_only
              ...ProductInterfaceLabels
              price_range {
                ...PriceRangeFields
              }
              ...SimpleProductCustomPricesFields
              ...SimpleProductStockDataFields
              ...ProductInterfaceListingsFields
              attributes_value(attribute_codes: $attributeCodes) {
                attribute_uid
                code
                label
                position
                sort_order
                uid
                value
              }
            }
            attributes {
              code
              label
              uid
            }
          }
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
  ${fragmentProductInterfaceLabels}
  ${fragmentSimpleProductCustomPrices}
  ${fragmentSimpleProductStockData}
  ${fragmentPriceRangeFields}
  ${fragmentProductInterfaceListingsFields}
  ${fragmentConfigurableProductHasVariants}
`;
