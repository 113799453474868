import { defineStore } from 'pinia';
import { ref } from '@nuxtjs/composition-api';
import type { SummaryInterface } from '@cellar-services/types';
import { bbxStore } from './bbx/bbx-store';

export const useCellarStore = defineStore('cellar', () => {
  // exporting from common store
  const {
    isLoading,
    isBlockingLoading,
    uniqueID,
    defaultID,
    linkedAccounts,
    setIsBlockingLoading,
    setIsLoading,
    activeRows,
    resetActiveRows,
    toggleRow,
    closeRow,
    resetStore,
  } = bbxStore();

  // State
  const stats = ref<SummaryInterface | null>(null);

  // Actions
  const setStats = (value: SummaryInterface | null) => {
    stats.value = value;
  };

  return {
    isLoading,
    isBlockingLoading,
    uniqueID,
    defaultID,
    linkedAccounts,
    setStats,
    setIsBlockingLoading,
    setIsLoading,
    stats,
    activeRows,
    resetActiveRows,
    toggleRow,
    closeRow,
    resetStore,
  };
});
