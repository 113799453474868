import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import {
  SubscribeEmailNewsletterOutput,
  SubscribeEmailToInterestOutput,
  SubscriptionStatusesEnum
} from '~/modules/GraphQL/types';
import { updateSubscriptionCommand } from './commands/updateSubscriptionCommand';
import type {
  UseNewsletterErrors,
  UseNewsletterInterface,
  UseNewsletterUpdateSubscriptionParams,
} from './useNewsletter';
import subscribeEmailToInterestGql from '@theme/customQueries/subscribeEmailToInterest.gql';
import { useApi } from '~/composables';
import subscribeEmailToNewsletterGql from '@theme/customQueries/subscribeEmailToNewsletter.gql';

/**
 * Allows updating the subscription status of
 * an email in the newsletter.
 *
 * See the {@link UseNewsletterInterface} for a list of methods and values available in this composable.
 */
export function useNewsletter(): UseNewsletterInterface {
  const context = useContext();
  const { mutate } = useApi();
  const loading = ref(false);
  const error = ref<UseNewsletterErrors>({
    updateSubscription: null,
    subscribeEmailToNewsletter: null,
    subscribeEmailToInterest: null,
  });

  const updateSubscription = async (params: UseNewsletterUpdateSubscriptionParams) => {
    Logger.debug('[Magento]: Update user newsletter subscription', { params });
    let result = SubscriptionStatusesEnum.Unsubscribed;

    try {
      loading.value = true;
      error.value.updateSubscription = null;

      const { status, errors } = await updateSubscriptionCommand.execute(context, params);
      result = status;
      error.value.updateSubscription = errors || null;
    } catch (err) {
      error.value.updateSubscription = err;
      Logger.error('useNewsletter/updateSubscription', err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  const subscribeEmailToNewsletter = async (params: UseNewsletterUpdateSubscriptionParams) => {
    Logger.debug('[Magento]: Update user newsletter subscription', { params });
    let result = SubscriptionStatusesEnum.Unsubscribed;

    try {
      loading.value = true;
      error.value.subscribeEmailToNewsletter = null;

      const { data: { subscribeEmailToNewsletter: { status }, errors}, } =
        await mutate<SubscribeEmailNewsletterOutput>(subscribeEmailToNewsletterGql, { ...params });

      result = status;
      error.value.subscribeEmailToNewsletter = errors || null;
    } catch (err) {
      error.value.subscribeEmailToNewsletter = err;
      Logger.error('useNewsletter/updateSubscription', err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  const subscribeEmailToInterest = async (params: UseNewsletterUpdateSubscriptionParams):
    Promise<SubscriptionStatusesEnum> => {
    let result = SubscriptionStatusesEnum.Unsubscribed;

    try {
      loading.value = true;
      error.value.subscribeEmailToInterest = null;

      const { data: { subscribeEmailToInterest: { status }, errors}, } =
        await mutate<SubscribeEmailToInterestOutput>(subscribeEmailToInterestGql, { ...params });

      result = status;
      error.value.subscribeEmailToInterest = errors || null;
    } catch (err) {

    } finally {
      loading.value = false;
    }

    return result;
  };

  return {
    updateSubscription,
    subscribeEmailToNewsletter,
    subscribeEmailToInterest,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useNewsletter';
export default useNewsletter;
