export default `
  mutation subscribeEmailToInterest(
    $email: String!,
    $privacy_policy: Boolean!,
    $marketing_comms: Boolean!
  ){
    subscribeEmailToInterest(
      email: $email,
      privacy_policy: $privacy_policy,
      marketing_comms: $marketing_comms
    ) {
      status
    }
  }
`
