



















































import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  ref,
  useContext,
  onMounted,
  watchEffect,
} from '@nuxtjs/composition-api';
import {
  ExtensiveLogo,
  Drinkaware,
  LinkItem,
  NavigationInterface,
  NavigationItem,
  RoyalWarrantItems,
  SocialIconsInterface,
} from '@amplience/types';
import { storeToRefs } from 'pinia';
import { useNavigation } from '@amplience/stores/navigation';
import Accordion from '@amplience/components/molecules/footer/Accordion.vue';
import Copyright from '@amplience/components/molecules/footer/Copyright.vue';
import LegalLinks from '@amplience/components/molecules/footer/LegalLinks.vue';
import Logo from '@amplience/components/atoms/Logo.vue';
import RoyalWarrants from '@amplience/components/molecules/footer/RoyalWarrants.vue';
import SocialIcons from '@amplience/components/content-type/SocialIcons.vue';

export default defineComponent({
  name: 'GlobalFooter',
  components: {
    Accordion,
    Copyright,
    LegalLinks,
    Logo,
    RoyalWarrants,
    SocialIcons,
  },
  props: {
    isGlobalFooter: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: Object as PropType<ExtensiveLogo>,
      default: () => ({
        image: null,
        label: 'Footer Logo',
      }),
    },
    navigation: {
      type: [Object, null] as PropType<NavigationInterface | null>,
      default: null,
    },
    socialIcons: {
      type: [Object, null] as PropType<SocialIconsInterface | null>,
      default: null
    },
    royalWarrants: {
      type: Object as PropType<RoyalWarrantItems>,
      default: () => ({
        enabled: false,
        items: () => [],
      }),
    },
    legalLinks: {
      type: [Array, null] as PropType<LinkItem[] | null>,
      default: null,
    },
    copyright: {
      type: String,
      default: '',
    },
    drinkaware: {
      type: [Object, null] as PropType<Drinkaware | null>,
      default: () => ({
        image: null,
        label: 'Drinkaware Logo',
        url: 'https://www.drinkaware.co.uk/',
      }),
    },
  },
  setup(props) {
    const context = useContext();
    const store = useNavigation();
    const items = ref<NavigationItem[]>([]);
    const { getSortedChildrenById } = storeToRefs(store);

    watchEffect(async() => {
      if (props.navigation?._meta?.deliveryId) {
        await store.fetchNodes(context);
        items.value = getSortedChildrenById.value(props.navigation._meta.deliveryId);
      }
    });

    const defaultLogo = computed(() => {
      return {
        url: props.isGlobalFooter ? '/bbr/logo/gold.svg' : '/bbr/logo/black.svg',
        alt: 'Footer Logo',
      }
    });

    return {
      items,
      defaultLogo,
    }
  }
});
