




































import {computed, defineComponent, onBeforeUnmount, onMounted, ref, useRoute, watch} from '@nuxtjs/composition-api';
import { useNavigation } from '@amplience/stores/navigation';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'HeaderNavigationParent',
  props: {
    title: {
      type: String,
      required: true,
    },
    deliveryId: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const navigation = useNavigation();
    const { getParentByUrl } = storeToRefs(navigation);
    const parent = ref<HTMLElement | null>(null);
    const isParentHovered = ref<boolean>(false);
    const isParentWrapped = ref<boolean>(false);
    const isParentFocused = ref<boolean>(false);
    const mouseEnterTimeout = ref<number | NodeJS.Timeout | null>(null);
    const parentFocusTimeout = ref<number | NodeJS.Timeout | null>(null);
    const navigationPosition = ref(null);
    const parentPosition = ref<number | null>(null);
    const isChildActive = computed<boolean>(() => {
      const parent = getParentByUrl.value(route.value.path);

      return parent ? parent.deliveryId === props.deliveryId : false;
    });

    // Design request to display groups/children of parent category after a short delay
    const handleMouseEnter = () => {
      mouseEnterTimeout.value = setTimeout(() => isParentHovered.value = true, 300);
    };

    const handleMouseLeave = () => {
      isParentHovered.value = false;

      if (mouseEnterTimeout !== null) {
        clearTimeout(mouseEnterTimeout.value as number);
      }
    }

    const handleFocusIn = () => {
      if (parentFocusTimeout !== null) {
        clearTimeout(parentFocusTimeout.value as number);
      }

      isParentFocused.value = true;
    }

    const handleFocusOut = () => {
      parentFocusTimeout.value = setTimeout(() => isParentFocused.value = false, 100);
    }

    // Tracking if the parent is wrapped on screen resize
    const updateParentPosition = () => {
      navigationPosition.value = document?.getElementById('global-navigation')?.getBoundingClientRect().top;
      parentPosition.value = parent.value?.getBoundingClientRect().top;

      if (navigationPosition.value) {
        isParentWrapped.value = navigationPosition.value !== parentPosition.value - 1;
      }
    }

    onMounted(() => {
      updateParentPosition();
      window.addEventListener('resize', updateParentPosition);
    })

    onBeforeUnmount(() => {
      window.addEventListener('resize', updateParentPosition);
    })

    return {
      parent,
      parentPosition,
      isParentHovered,
      isParentFocused,
      isWrapped: computed(() => isParentWrapped.value),
      isActive: (link: string): boolean => link === route.value.path || isChildActive.value,
      handleMouseEnter,
      handleMouseLeave,
      handleFocusIn,
      handleFocusOut,
    }
  }
})
