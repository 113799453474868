import { render, staticRenderFns } from "./AccountModal.vue?vue&type=template&id=5f2a3986&scoped=true"
import script from "./AccountModal.vue?vue&type=script&lang=ts"
export * from "./AccountModal.vue?vue&type=script&lang=ts"
import style0 from "./AccountModal.vue?vue&type=style&index=0&id=5f2a3986&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2a3986",
  null
  
)

export default component.exports