import {
  useBuyingOption,
  BuyingOptionEnum,
} from '@theme/modules/catalog/stores/buyingOption';
import {
  getFamilyType,
  getSellByCaseOnly,
  getCustomPrice as getCustomPriceGetter,
} from '@theme/modules/catalog/getters/productGetters';
import { FamilyType } from '@theme/modules/catalog/stores/product';
import type { ProductInterface, SimpleProduct } from '~/modules/GraphQL/types';
import { useFeatureToggle } from '@theme/composables';

interface UseCustomPriceInterface {
  hasCustomPrice: () => boolean;
  getCustomPrice: () => number;
  getCustomSpecialPrice: () => number | null;
}

/**
 * useCustomPrice composable is used to get the custom price of the product
 * based on the selected mode and family type
 *
 * @param product ProductInterface
 * @returns UseCustomPriceInterface
 *
 * @remarks
 *
 * If the family type is `wines`, `spirits` or `other_drinks` and the selected mode is `FOR_DELIVERY`
 * then we need to display the `price` if the `sell_by_case_only` is `false`
 * otherwise we need to display the `price_per_case`
 *
 * If the family type is `wines`, `spirits` or `other_drinks` and the selected mode is `IN_BOND`
 * then we need to display the `price_per_case`
 *
 * For the `assortment_mixed_case` we always display `price_value`
 *
 * For the `bundle_mixed_case` we always display `price_value`
 *
 * For other product types we always display `price_value`
 *
 * For `listings` we need to use key - `listing_price` under product variant
 */
export function useCustomPrice(product: ProductInterface | null): UseCustomPriceInterface {
  const { isCustomPriceEnabled } = useFeatureToggle();
  const { selectedMode } = useBuyingOption();
  const familyType = getFamilyType(product);
  const sellByCaseOnly = getSellByCaseOnly(product);
  const {
    price,
    pricePerCase,
    specialPrice,
    specialPricePerCase
  } = getCustomPriceGetter(product as SimpleProduct);

  const hasCustomPrice = () => {
    return isCustomPriceEnabled.value && (price > 0 || pricePerCase > 0);
  }

  const getCustomPrice = () => {
    switch (familyType) {
      case FamilyType.Wines:
      case FamilyType.Spirits:

      case FamilyType.OtherDrinks:
        if (selectedMode.type === BuyingOptionEnum.FOR_DELIVERY) {
          return sellByCaseOnly ? pricePerCase : price;
        } else if (selectedMode.type === BuyingOptionEnum.IN_BOND) {
          return pricePerCase;
        }

      default:
        return price;
    }
  }

  const getCustomSpecialPrice = () => {
    switch (familyType) {
      case FamilyType.Wines:
      case FamilyType.Spirits:

      case FamilyType.OtherDrinks:
        if (selectedMode.type === BuyingOptionEnum.FOR_DELIVERY) {
          return sellByCaseOnly ? specialPricePerCase : specialPrice;
        } else if (selectedMode.type === BuyingOptionEnum.IN_BOND) {
          return specialPricePerCase;
        }

      default:
        return specialPrice;
    }
  }

  return {
    hasCustomPrice,
    getCustomPrice,
    getCustomSpecialPrice,
  }
}

export default useCustomPrice;
