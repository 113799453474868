export default `
  mutation clearWishlist($wishlistId: ID!) {
    clearWishlist(wishlistId: $wishlistId) {
      user_errors {
        code
        message
      }
    }
  }
`;
