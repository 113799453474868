
























































import {computed, defineComponent, ref, watch} from '@nuxtjs/composition-api';
import {TextField} from '@theme/components';

export default defineComponent({
  name: 'PasswordInput',
  components: {
    TextField,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    showWarnings: {
      type: Boolean,
      default: true
    },
    confirmMode: {
      type: Boolean,
      default: false
    },
    confirmPassword: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Password',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, {emit}) {
    const password = ref<string | null>(null);
    const isPasswordVisible = ref<boolean>(false);
    const isPasswordValid = computed<boolean>(() => Object.values(rules.value).every((el: boolean) => el === true));
    const rules= ref<any>({
      hasUppercase: false,
      hasLowercase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isLengthValid: false
    });
    const isValid = ref<boolean>(false);
    const isDirty = ref<boolean>(false);

    const togglePasswordVisibility = (): void => {
      isPasswordVisible.value = !isPasswordVisible.value;
    };

    const handleInput = (): void => {
      emit('input', password.value); // Emit the updated value to the parent
      validatePassword();

      if (!isDirty.value && password.value.length > 0) {
        isDirty.value = true; // Mark input as dirty once user starts typing
      }
    }

    const validatePassword = (): void => {
      rules.value.hasUppercase = /[A-Z]/.test(password.value);
      rules.value.hasLowercase = /[a-z]/.test(password.value);
      rules.value.hasNumber = /[0-9]/.test(password.value);
      rules.value.hasSpecialChar = /[!@#$%^&*(),.?":{}|<>+=\[;’\/§±`~ “]/.test(password.value);
      rules.value.isLengthValid = password.value.length > 8;
    }

    watch(() => props.value, () => {
      if (props.value && !password.value) {
        password.value = props.value;
      }
    }, { immediate: true });

    return {
      password,
      isValid,
      isDirty,
      isPasswordVisible,
      isPasswordValid,
      rules,
      togglePasswordVisibility,
      handleInput
    }
  }
})
