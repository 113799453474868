import { useRoute, useRouter } from '@nuxtjs/composition-api';

export function useQueryParams() {
  const route = useRoute();
  const router = useRouter();

  const setQueryParams = (query) => {
    if (window?.history) {
      const routeData = router.resolve({ query }, route.value, true);
      window.history.pushState({}, null, routeData.href);
    }
  };

  return {
    setQueryParams,
  };
}

export default useQueryParams;
