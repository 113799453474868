



























import { defineComponent, onBeforeUnmount, onMounted, ref, useRoute } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

// 48 (button bottom offset) + 24 (button top negative offset)
const THRESHOLD_OFFSET = 72;

export default defineComponent({
  name: 'ScrollToTop',
  components: {
    SfButton
  },
  setup() {
    const showScrollToTop = ref<boolean>(false);
    const isFooterReached = ref<boolean>(false);
    const prevScrollPos = ref<number>(0);
    const footerPosition = ref(null);
    const route = useRoute();
    const isOnCheckout = ref<boolean>(false);

    const scrollTop = (): void => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      window.setTimeout(() => document.getElementById('logo')?.focus(), 1000);
    };

    const handleScroll = (): void => {
      isOnCheckout.value = !!route.value.path.match(/checkout/)?.length;
      const currentScrollPos = window.pageYOffset;
      showScrollToTop.value = currentScrollPos < prevScrollPos.value && currentScrollPos > 400;
      prevScrollPos.value = currentScrollPos;

      if (!footerPosition.value?.getBoundingClientRect) return;

      const footerRect = footerPosition.value.getBoundingClientRect();
      isFooterReached.value = footerRect.top <= window.innerHeight - THRESHOLD_OFFSET && footerRect.bottom >= 0;
    };

    onMounted(() => {
      footerPosition.value = window.document.getElementById('footer');
      window.addEventListener('scroll', handleScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      scrollTop,
      isOnCheckout,
      showScrollToTop,
      isFooterReached
    };
  },
})
