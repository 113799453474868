import { render, staticRenderFns } from "./AmplienceHeader.vue?vue&type=template&id=5f2fc050&scoped=true"
import script from "./AmplienceHeader.vue?vue&type=script&lang=ts"
export * from "./AmplienceHeader.vue?vue&type=script&lang=ts"
import style0 from "./AmplienceHeader.vue?vue&type=style&index=0&id=5f2fc050&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2fc050",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/var/www/modules/amplience/components/content-type/Navigation.vue').default})
