





























import { defineComponent, ref, computed, useContext } from '@nuxtjs/composition-api';
import TouchTarget from '~/bbrTheme/components/atoms/TouchTarget.vue';

export default defineComponent({
  name: 'Tooltip',
  components: {
    TouchTarget
  },
  props: {
    tooltipId: {
      type: String,
      required: true
    },
    tooltipText: {
      type: String,
      required: true
    },
    tooltipTriggerLabel: {
      type: String,
      default: 'More information'
    }
  },
  setup(props) {
    const context = useContext();
    const isTooltipShown = ref(false);

    const tooltipLabel = context.app.i18n.t(props.tooltipTriggerLabel);

    return {
      isTooltipShown,
      tooltipLabel
    }
  },
})
