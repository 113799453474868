import { breakpoints } from '~/utils/breakpoints';

export function useAlignGridItems(grid) {
  const alignGridItems = () => {
    const heights = [];
    const margins = [];
    let chunkSize;

    if (window.innerWidth >= breakpoints.desktop) {
      chunkSize = 3;
    } else if (window.innerWidth < breakpoints.desktop && window.innerWidth > breakpoints.mobile) {
      chunkSize = 2;
    } else {
      chunkSize = 1;
    }

    grid.value?.$children?.forEach((item, idx) => {
      const elements = item?.$el?.children;
      [...elements]?.map(el => {
        if (el.dataset?.role === 'desc') {
          heights[idx] = el.offsetHeight;
        }
      });
    });

    for (let i = 0; i < heights.length; i += chunkSize) {
      const chunk = heights.slice(i, i + chunkSize);
      const maxHeight = Math.max(...chunk);
      chunk.forEach(item => {
        margins.push(maxHeight - item);
      });
    }

    grid.value?.$children?.map((item, idx) => {
      const elements = item?.$el?.children;
      [...elements]?.map(el => {
        if (el.dataset?.role === 'action-block') {
          el.style.marginTop = `${margins[idx]}px`;
        }
      });
    });
  };

  return {
    alignGridItems,
  };
}

export default useAlignGridItems;
