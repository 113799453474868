import { UseContextReturn } from '~/types/core';
import {
  SubscribeEmailToNewsletterMutationVariables,
  SubscribeEmailToNewsletterOutput,
} from '~/modules/GraphQL/types';
import type { UseNewsletterUpdateSubscriptionParams } from '~/composables';

export const updateSubscriptionCommand = {
  execute: async (context: UseContextReturn, params: UseNewsletterUpdateSubscriptionParams):
    Promise<SubscribeEmailToNewsletterOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.subscribeEmailToNewsletter({
      email: params.email,
      privacy_policy: params.privacy_policy,
      marketing_comms: params.marketing_comms,
    } as SubscribeEmailToNewsletterMutationVariables,
      params.customQuery = { subscribeEmailToNewsletter: 'subscribeEmailToNewsletter' } ?? null,
      params?.customHeaders ?? null
    );

    return {
      status: data?.subscribeEmailToNewsletter?.status ?? null,
      errors: errors ? errors.map(error => error.message) : null,
    };
  },
};
