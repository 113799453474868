












































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  ComponentPublicInstance
} from '@nuxtjs/composition-api';

/**
 * This is a fully accessible automatic selection tabs component
 * 
 * ref: https://www.w3.org/WAI/ARIA/apg/patterns/tabs/
 */
export default defineComponent({
  name: 'Tabs',
  inheritAttrs: false,
  props: {
    titles: {
      type: Array as () => Array<string>,
      required: true,
    },
    tabs: {
      type: Array as () => Array<ComponentPublicInstance & { name: string }>,
      required: true,
    },
    defaultOpenTabIndex: {
      type: Number,
      default: 0
    }
  },
  emits: ['on-tab-change'],
  setup(props, { emit}) {
    const count = ref(props.titles.length);
    const active = ref(0);

    const setActive = (key) => {
      active.value = key;
      emit('on-tab-change', key);
    };

    const setActiveLeft = (event) => {
      if (active.value === 0) {
        setActive(count.value - 1);
      } else {
        setActive(active.value - 1);
      }

      document.getElementById(`tab-${active.value}`).focus();
    };

    const setActiveRight = () => {
      if (active.value === count.value - 1) {
        setActive(0);
      } else {
        setActive(active.value + 1);
      }

      document.getElementById(`tab-${active.value}`).focus();
    };

    const setHome = (event) => {
      setActive(0);
      document.getElementById(`tab-${active.value}`).focus();
    };

    const setEnd = () => {
      setActive(count.value - 1);
      document.getElementById(`tab-${active.value}`).focus();
    };

    onMounted(() => {
      if (props.tabs.length - 1 < props.defaultOpenTabIndex) {
        setActive(0);
      } else if (props.defaultOpenTabIndex !== 0) {
        setActive(props.defaultOpenTabIndex);
      }
    });

    onUnmounted(() => {
      setActive(0);
    });

    return {
      active,
      setActive,
      setActiveLeft,
      setActiveRight,
      setHome,
      setEnd,
    }
  }
});
