import { useContext } from '@nuxtjs/composition-api';
import cartGetters from './cartGetters';
import productGettersExtended from '@theme/modules/catalog/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import type { Cart, CartItemInterface, ConfigurableCartItem, GroupCartItemInterface } from '~/modules/GraphQL/types';

export enum ProductGroupsEnum {
  DEFAULT = 'default',
  INBOND = 'inbond',
  EVENTS = 'events',
  VOUCHERS = 'vouchers',
}

export enum ProductGroupComponentEnum {
  DEFAULT = 'ProductGroupDefault',
  INBOND = 'ProductGroupInBond',
  EVENTS = 'ProductGroupEvents',
  VOUCHERS = 'ProductGroupVouchers',
}

export interface GroupType {
  code: `${ProductGroupsEnum}`;
  component: `${ProductGroupComponentEnum}`;
}

/**
 * `groupTypes` array contains the group types for the cart items.
 */
export const groupTypes: GroupType[] = [
  {
    code: ProductGroupsEnum.DEFAULT,
    component: ProductGroupComponentEnum.DEFAULT
  },
  {
    code: ProductGroupsEnum.INBOND,
    component: ProductGroupComponentEnum.INBOND
  },
  {
    code: ProductGroupsEnum.EVENTS,
    component: ProductGroupComponentEnum.EVENTS
  },
  {
    code: ProductGroupsEnum.VOUCHERS,
    component: ProductGroupComponentEnum.VOUCHERS
  },
];

/**
 * `getProductGroupType` function returns the group type for the cart item.
 */
export const getProductGroupType = (code: string): GroupType => {
  return groupTypes.find(group => group.code === code) ?? groupTypes[0];
}

/**
 * `getProductGroupSortOrder` function returns the sort order for the product group.
 */
const getProductGroupSortOrder = (groupName: string): number => {
  const sortOrder = {
    [ProductGroupsEnum.DEFAULT]: 10,
    [ProductGroupsEnum.INBOND]: 20,
    [ProductGroupsEnum.EVENTS]: 30,
    [ProductGroupsEnum.VOUCHERS]: 40,
  };

  return sortOrder[groupName] ?? 0;
}

/**
 * `getItemAmplienceImage` function returns the Amplience image URL for the cart item.
 */
export const getItemAmplienceImage = (cartItem: CartItemInterface): string => {
  const { $getAmplienceImageUrl } = useContext();

  return $getAmplienceImageUrl(cartItem.product as Product);
}

/**
 * `getGroupedItems` function returns the grouped items from the cart.
 */
export const getGroupedItems = (cart: Cart): GroupCartItemInterface[] => {
  if (!cart || !cart.grouped_items) {
    return [];
  }

  return cart.grouped_items;
};

/**
 * `getItemSellByCaseOnly` function returns a boolean value if the product is sell by case only.
 */
export const getItemSellByCaseOnly = (cartItem: ConfigurableCartItem): boolean => {
  const product = cartGetters.getConfiguredVariant(cartItem);

  return productGettersExtended.getSellByCaseOnly(product);
}

/**
 * `getItemCaseOrderUnit` function returns the Case Order Unit for the cart item.
 */
export const getItemCaseOrderUnit = (cartItem: ConfigurableCartItem): number => {
  const product = cartGetters.getConfiguredVariant(cartItem);
  const caseOrderUnit = productGettersExtended.getCaseOrderUnit(product);

  return parseInt(caseOrderUnit, 10);
}

/**
 * `getItemQtyStep` function returns the quantity step for the cart item.
 *  - If the product is INBOND, the step is equal to the Case Order Unit.
 *  - If the product is DELIVERY and sell by case only, the step is equal to the Case Order Unit.
 *  - If the product is DELIVERY and sell by case only is set to `false`, the step is equal to 1.
 *  - If the product is VOUCHERS or EVENTS, the step is equal to 1.
 */
export const getItemQtyStep = (cartItem: ConfigurableCartItem, group: `${ProductGroupsEnum}`): number => {
  const INITIAL_QTY_STEP = 1;
  const sellByCaseOnly = getItemSellByCaseOnly(cartItem);
  const caseQty = getItemCaseOrderUnit(cartItem);

  switch (group) {
    case ProductGroupsEnum.DEFAULT:
      if (sellByCaseOnly && caseQty) {
        return caseQty;
      }

      return INITIAL_QTY_STEP;
    case ProductGroupsEnum.INBOND:
      return caseQty;
    case ProductGroupsEnum.EVENTS:
      return INITIAL_QTY_STEP;
    case ProductGroupsEnum.VOUCHERS:
      return INITIAL_QTY_STEP;
    default:
      return INITIAL_QTY_STEP;
  }
}

export interface CartGettersExtended {
  getItemQtyStep(cartItem: CartItemInterface, group: `${ProductGroupsEnum}`): number;
  getItemSellByCaseOnly(cartItem: CartItemInterface): boolean;
  getItemCaseOrderUnit(cartItem: CartItemInterface): number;
  getItemAmplienceImage(cartItem: CartItemInterface): string;
  getGroupedItems(cart: Cart): GroupCartItemInterface[];
  getProductGroupSortOrder(groupName: string): number;
  getProductGroupType(code: string): GroupType;
}

export const cartGettersExtended: CartGettersExtended = {
  getItemQtyStep,
  getItemSellByCaseOnly,
  getItemCaseOrderUnit,
  getItemAmplienceImage,
  getGroupedItems,
  getProductGroupSortOrder,
  getProductGroupType,
};
