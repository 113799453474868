import {SignUpForm} from '@theme/composables/useAuth/useAuth';
import {setIsLoggedIn} from '@theme/helpers/dualRunning';
import { Logger } from '~/helpers/logger';
import { ref, useContext } from '@nuxtjs/composition-api';
import {UseCartErrors, useUiNotification} from '~/composables';
import useUser from '~/modules/customer/composables/useUser';
import {useHybrisUser} from '~/modules/customer/composables/useUser/useHybrisUser';
import {useCustomerStore} from '~/modules/customer/stores/customer';


export function useAuth (): any {
  const { send: sendNotification } = useUiNotification();
  const context = useContext();
  const { redirectIfCheckout } = useUser();
  const { app, $vsf } = useContext();
  const customerStore  = useCustomerStore();
  const loading = ref<boolean>(false);
  const titles: string[] = [
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Mr and Mrs',
    'Sir',
    'Dr',
    'Prof',
    'Lord',
    'Lady',
    'Rev',
    'Dame',
    'Master'
  ];
  const errorHybris = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { loadCartAndUser } = useHybrisUser(errorHybris);

  const signUp = async (form: SignUpForm): Promise<void> => {
    try {
      loading.value = true;

      await context.app.$vsf.$hybris.api.register(form);

      redirectIfCheckout();

      customerStore.setIsLoggedIn(true);
      setIsLoggedIn(app.$cookies, true);

      await loadCartAndUser();

      sendNotification({
        id: Symbol('sign_up_success'),
        type: 'success',
        message: context.app.i18n.t(
          'Registration successful!'
        ) as string,
        icon: 'success',
        persist: false,
        title: context.app.i18n.t(
          'Welcome to our platform.'
        ) as string,
      });
    } catch (error) {
      sendNotification({
        id: Symbol('sign_up_error'),
        type: 'danger',
        message: context.app.i18n.t(
          'Please try again later or get in touch with us via email, phone or live chat'
        ) as string,
        icon: 'danger',
        persist: false,
        title: context.app.i18n.t(
          'We can not process your enquiry at the moment'
        ) as string,
      });
      Logger.error('useAuth/signUp', error);
    } finally {
      loading.value = false;
    }
  }

  return {
    titles,
    signUp
  }
}
