import { defineStore } from 'pinia';
import {
  ref,
  computed,
} from '@nuxtjs/composition-api';

export interface BidAlertThresholdTypeInterface {
  type: string;
  label: string;
  isSelected: boolean;
}

export const BID_ALERTS_THRESHOLD_TYPES: BidAlertThresholdTypeInterface[] = [{
  type: 'BELOW_LIV_EX',
  label: 'Percentage below Liv-ex market price',
  isSelected: true,
}, {
  type: 'ABOVE_LOWEST_PURCHASE',
  label: 'Percentage above lowest purchase price',
  isSelected: false,
}, {
  type: 'ALL_BIDS',
  label: 'No threshold - receive all bids',
  isSelected: false,
}];

export const DEFAULT_BID_ALERTS_THRESHOLD = 15;

export const useBBXAccountStore = defineStore('bbx-account', () => {
  // State
  // Settings for bid alerts for the entire cellar
  const bidAlertsEnabled = ref(true);
  const bidAlertsThreshold = ref(DEFAULT_BID_ALERTS_THRESHOLD);
  const bidAlertsThresholdTypes = ref(BID_ALERTS_THRESHOLD_TYPES);

  // Getters
  const selectedBidAlertsThresholdType = computed(() => {
    return bidAlertsThresholdTypes.value.find((option) => option.isSelected)
  });
  const isBidAlertsThresholdAvailable = computed(() => {
    return selectedBidAlertsThresholdType.value.type !== 'ALL_BIDS';
  });

  // Actions
  const setBidAlertsEnabled = (value) => (bidAlertsEnabled.value = value);
  const setBidAlertsThreshold = (value) => (bidAlertsThreshold.value = value);

  const setBidAlertsThresholdType = (value) => {
    bidAlertsThresholdTypes.value.forEach((option) => {
      option.isSelected = option.type === value;
    });
  };

  const resetStore = () => {
    setBidAlertsEnabled(true);
    setBidAlertsThreshold(DEFAULT_BID_ALERTS_THRESHOLD);

    bidAlertsThresholdTypes.value = BID_ALERTS_THRESHOLD_TYPES;
  };

  return {
    DEFAULT_BID_ALERTS_THRESHOLD,
    bidAlertsEnabled,
    setBidAlertsEnabled,
    bidAlertsThreshold,
    setBidAlertsThreshold,
    selectedBidAlertsThresholdType,
    bidAlertsThresholdTypes,
    setBidAlertsThresholdType,
    isBidAlertsThresholdAvailable,
    resetStore,
  };
});
