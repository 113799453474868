import { Plugin } from '@nuxt/types';
import { PublicClientApplication } from '@azure/msal-browser';
import { AzureModuleOptions, MsalConfig } from './types';

declare module 'vue/types/vue' {
  interface Vue {
    $msal: PublicClientApplication;
    $msalConfig: MsalConfig;
  }
}

declare module '@nuxt/types' {
  interface Context {
    $msal: PublicClientApplication;
    $msalConfig: MsalConfig;
  }
}

const msalPlugin: Plugin = (context, inject) => {
  const options = JSON.parse('{"scopes":"openid","clientId":"83b95330-72d9-4653-bab7-a42d00be3781","tenantId":"a28003bc-41e3-4bad-9a7c-b54310a1a8bc","redirectUri":"https://berry-bros-prodsupp.europe-west1.gcp.storefrontcloud.io/auth/redirect","signInPolicy":"https://bbrb2c.b2clogin.com/bbrb2c.onmicrosoft.com/B2C_1_Magento_Temp_SignInAndUp","signUpPolicy":"https://bbrb2c.b2clogin.com/bbrb2c.onmicrosoft.com/B2C_1_Magento_Temp_signup","knownAuthorities":"bbrb2c.b2clogin.com","logoutRedirectUri":"https://pps-alokai.bbr.com","checkoutRedirectUri":"https://berry-bros-prodsupp.europe-west1.gcp.storefrontcloud.io/auth/checkout-redirect","loginRedirectUri":"https://berry-bros-prodsupp.europe-west1.gcp.storefrontcloud.io/auth/login-redirect"}') as AzureModuleOptions;
  const {
    clientId,
    redirectUri,
    knownAuthorities,
    scopes,
    signInPolicy,
    signUpPolicy,
    logoutRedirectUri,
    checkoutRedirectUri,
    loginRedirectUri,
  } = options;

  inject('msal', new PublicClientApplication({
    auth: {
      clientId,
      redirectUri,
      postLogoutRedirectUri: logoutRedirectUri,
      knownAuthorities: knownAuthorities.split(','),
    },
    cache: {
      cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
        secureCookies: true,
    },
  }));

  inject('msalConfig', {
    scopes: scopes.split(','),
    signInPolicy,
    signUpPolicy,
    logoutRedirectUri,
    checkoutRedirectUri,
    loginRedirectUri,
    redirectUri,
  });
};

export default msalPlugin;
