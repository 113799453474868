import { useContext, ref, onMounted } from '@nuxtjs/composition-api';
import { UseCaptchaInterface } from './useCaptcha';

export function useCaptcha(): UseCaptchaInterface {
  const { $recaptcha, $config } = useContext() as any;
  const isInitialized = ref<boolean>(false);
  const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

  const getToken = async (): Promise<string | null> => {
    try {
      const token = await $recaptcha.execute('login');

      return token;
    } catch (e) {
      console.log(e);
    }

    return null;
  };

  const reset = async () => {
    try {
      await $recaptcha.destroy();
    } catch (e) {
      console.log(e);
    }
  };

  onMounted(async () => {
    if (isRecaptchaEnabled.value && !isInitialized.value) {
      await $recaptcha.init();
    }
  });

  return {
    reset,
    getToken,
    isInitialized,
    isRecaptchaEnabled,
  };
}

export * from './useCaptcha';
export default useCaptcha;
