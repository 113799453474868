import { Renderer, marked } from 'marked';

export default class CustomRenderer extends Renderer {
  table(header: string, body: string): string {
    if (body) body = `<tbody>${body}</tbody>`;

    return `<table class="custom-table">\n<thead>\n${header}</thead>\n${body}</table>\n`;
  }

  /**
   * @param {string} content
   */
  tablerow(content) {
    const head = /<th>/g;
    const body = /<td\b[^>]*>|<td class="empty"\b[^>]*>/g;
    const headMatches = content.match(head);
    const bodyMatches = content.match(body);
    const headCount = headMatches ? headMatches.length : 0;
    const bodyCount = bodyMatches ? bodyMatches.length : 0;

    if (headCount === 3 || bodyCount === 3) {
      return `<tr class="three-columns">\n${content}</tr>\n`;
    } else if (headCount === 4 || bodyCount === 4) {
      return `<tr class="four-columns">\n${content}</tr>\n`;
    } else if (headCount === 5 || bodyCount === 5) {
      return `<tr class="five-columns">\n${content}</tr>\n`;
    } else if (headCount >= 6 || bodyCount >= 6) {
      return `<tr class="six-columns">\n${content}</tr>\n`;
    }

    return `<tr>\n${content}</tr>\n`;
  }

  tablecell(content, flags) {
    const type = flags.header ? 'th' : 'td';
    let tag = flags.align ? `<${type} align="${flags.align}">` : `<${type}>`;

    if (content === '')
      tag = flags.align ? `<${type} class="empty" align="${flags.align}">` : `<${type} class="empty">`;

    return tag + content + `</${type}>\n`;
  }

  /**
   * @param {string} quote
   */
  blockquote(quote) {
    return `<div class="intro">\n${quote}</div>\n`;
  }

  listitem(text: string, task: boolean, checked: boolean): string {
    return `<li><span class="list-item-icon" aria-hidden="true"></span>${text}</li>\n`;
  }

  html(html) {
    try {
      const text = html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
      const updatedText = marked.parse(text);
      html = html.replace(text.trim(), updatedText);
      html = html.replace(/<p\b/g, '<div').replace(/<\/p>/g, '</div>');
    } catch (e) {
      console.error(e);
    }

    return html;
  }
}
