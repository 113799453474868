import { readonly, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useUiNotification } from '~/composables';
import { useHybrisCartStore } from '~/modules/checkout/stores/useHybrisCart';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export const useHybrisCart = (loading, error) => {
  const context = useContext();
  const hybrisCartStore = useHybrisCartStore();
  const { setHybrisCart } = hybrisCartStore;
  const { send: sendNotification } = useUiNotification();
  const customerStore = useCustomerStore();

  // Loads Hybris cart and sets the necessary cookies from its response
  const load = async () => {
    const response = await context.app.context.$vsf.$hybris.api.getCart();

    Logger.debug('[Hybris] cart:', { response });

    setHybrisCart(response.data);

    const apiState = context.app.context.$vsf.$magento.config.state;

    if (response.data.cartData.user.uid != 'anonymous') {
      customerStore.setIsLoggedIn(true);
      apiState.setCustomerToken(true);
    } else {
      customerStore.setIsLoggedIn(false);
      apiState.setCustomerToken(false);
    }

    return response.data;
  };

  const clear = () => setHybrisCart(null);

  // Adds item to Hybris cart, also reloads the cart after a successful add
  const addItem = async ({ product, quantity, productConfiguration }): Promise<any> => {
    Logger.debug('useCart.addItem-hybris', { product, quantity });

    let cartItem = null;

    try {
      loading.value = true;

      load();

      const variantSku =
        productConfiguration?.variants[0]?.product?.sku ||
        productConfiguration?.variants[0]?.sku ||
        product.sku;
      const extListingId = productConfiguration?.variants[0].product?.ext_listing_id || product?.ext_listing_id;
      const caseSize = Number(productConfiguration?.variants[0]?.attributes?.find(
        attribute => attribute.code === 'case_order_unit'
      )?.label) || 1;

      let quantityMultiplier = 1;
      let isInBondPrice = false;

      // For bottles, this is 1. For cases, this is the case size
      if (productConfiguration) {
        quantityMultiplier = productConfiguration.isBottle ? 1 : caseSize;
        isInBondPrice = !productConfiguration.isBottle || !!extListingId;
      }

      cartItem = await context.app.context.$vsf.$hybris.api.addToCart({
        quantity: quantityMultiplier * quantity,
        productCodePost: variantSku,
        isInBondPrice,
        bbxListingId: extListingId,
      });

      // it will get to point only on a 200 response
      if (cartItem.data?.info) {
        let responseInfo = cartItem.data.info[0];

        sendNotification({
          id: Symbol('info_adding_to_cart'),
          message: '',
          type: 'warning',
          icon: 'warning',
          persist: false,
          title: context.app.i18n.t(responseInfo.message) as string,
        });
      }

      if (cartItem.errors) {
        error.value.addItem = {
          message: context.app.i18n.t(cartItem.errors[0].message) as string,
        };
      }

      const updatedCart = await context.app.context.$vsf.$hybris.api.getCart();

      setHybrisCart(updatedCart.data);

    } catch (err) {
      error.value.addItem = err;
      Logger.error('useHybrisCart/addItem', err);
    } finally {
      if (error.value.addItem) {
        sendNotification({
          id: Symbol('error_adding_to_cart'),
          message: '',
          type: 'danger',
          icon: 'danger',
          persist: false,
          title: context.app.i18n.t(error.value?.addItem?.message || 'Failed to add the item to cart.') as string,
        });
      }

      loading.value = false;

      return cartItem?.data;
    }
  };

  const removeItem = async ({ entryNumber }): Promise<void> => {
    Logger.debug('useCart.removeItem-hybris', { entryNumber });

    try {
      loading.value = true;

      load();

      const cartItem = await context.app.context.$vsf.$hybris.api.updateCart({
        quantity: 0,
        entryNumber,
      });

      // it will get to point only on a 200 response
      if (cartItem.data?.info) {
        let responseInfo = cartItem.data.info[0];

        sendNotification({
          id: Symbol('info_remove_item_from_cart'),
          message: '',
          type: 'warning',
          icon: 'warning',
          persist: false,
          title: context.app.i18n.t(responseInfo.message) as string,
        });
      }

      if (cartItem.errors) {
        error.value.addItem = {
          message: context.app.i18n.t(cartItem.errors[0].message) as string,
        };
      }

      const updatedCart = await context.app.context.$vsf.$hybris.api.getCart();

      setHybrisCart(updatedCart.data);
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useHybrisCart/addItem', err);
    } finally {
      if (error.value.addItem) {
        sendNotification({
          id: Symbol('error_remove_item_from_cart'),
          message: '',
          type: 'danger',
          icon: 'danger',
          persist: false,
          title: context.app.i18n.t(
            error.value?.addItem?.message || 'Failed to remove the item from the cart.'
          ) as string,
        });
      }

      loading.value = false;
    }
  };

  const updateItemQty = async ({ entryNumber, quantity }): Promise<void> => {
    Logger.debug('useCart.updateItem-hybris', { entryNumber, quantity });

    try {
      loading.value = true;

      const cartItem = await context.app.context.$vsf.$hybris.api.updateCart({
        quantity,
        entryNumber,
      });

      // it will get to point only on a 200 response
      if (cartItem.data?.info) {
        let responseInfo = cartItem.data.info[0];

        sendNotification({
          id: Symbol('info_update_cart_item'),
          message: '',
          type: 'warning',
          icon: 'warning',
          persist: false,
          title: context.app.i18n.t(responseInfo.message) as string,
        });
      }

      if (cartItem.errors) {
        error.value.addItem = {
          message: context.app.i18n.t(cartItem.errors[0].message) as string,
        };
      }

      const updatedCart = await context.app.context.$vsf.$hybris.api.getCart();

      setHybrisCart(updatedCart.data);
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useHybrisCart/addItem', err);
    } finally {
      if (error.value.addItem) {
        sendNotification({
          id: Symbol('error_update_cart_item'),
          message: '',
          type: 'danger',
          icon: 'danger',
          persist: false,
          title: context.app.i18n.t(
            error.value?.addItem?.message || 'Failed to update the product quantity.'
          ) as string,
        });
      }

      loading.value = false;
    }
  };

  return {
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    loading: readonly(loading),
    error: readonly(error),
  };
};
