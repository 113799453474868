import { ref, useContext } from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import { UseAuthInterface } from './useAuth';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import type { GenerateCustomerTokenMutationResult } from '~/modules/assisted-shopper/types';
import GENERATE_CUSTOMER_TOKEN_AS_ADMIN from '~/modules/assisted-shopper/mutations/generateCustomerTokenAsAdmin.gql';

export const useAuth = (): UseAuthInterface => {
  const error = ref<string>('');
  const { app } = useContext();
  const { mutate } = useApi();
  const { load: loadCart, clear: clearCart } = useCart();
  const { load: loadCustomer, logout: logoutCustomer } = useUser();
  const { load: loadWishlist } = useWishlist();
  const store = useCustomerStore();

  const authCustomer = (token: string): void => {
    const apiState = app.context.$vsf.$magento.config.state;

    console.log('[setIsLoggedIn] Assisted shopper customer login.');
    store.setIsLoggedIn(true);
    apiState.setCustomerToken(token);
  };

  const login = async (token: string) => {
    const { data, errors }
      = await mutate<GenerateCustomerTokenMutationResult>(GENERATE_CUSTOMER_TOKEN_AS_ADMIN, { token });

    if (data?.generateCustomerTokenAsAdminV2?.customer_token) {
      authCustomer(data.generateCustomerTokenAsAdminV2.customer_token);
      loadCart();
      loadCustomer();
      loadWishlist();
    } else {
      error.value = errors?.[0]?.message || '';
    }
  };

  const logout = async () => {
    await logoutCustomer({});
    clearCart({});
  };

  return {
    error,
    login,
    logout,
  };
};
