export enum SortingOptionsValuesEnum {
  DEFAULT = '',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  VINTAGE_ASC = 'vintage_year_ASC',
  VINTAGE_DESC = 'vintage_year_DESC',
  PROPERTY_ASC = 'property_ASC',
  PROPERTY_DESC = 'property_DESC',
  MATURITY = 'maturity_ASC',
}

export interface SortingOption {
  label: string;
  value: SortingOptionsValuesEnum;
}

export interface SortingModel {
  selected: string;
  options: SortingOption[];
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Recommended',
    value: SortingOptionsValuesEnum.DEFAULT,
  },
  {
    label: 'Price (Low - High)',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
  {
    label: 'Price (High - Low)',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  },
  {
    label: 'Vintage Ascending',
    value: SortingOptionsValuesEnum.VINTAGE_ASC,
  },
  {
    label: 'Vintage Descending',
    value: SortingOptionsValuesEnum.VINTAGE_DESC,
  },
  {
    label: 'Maturity',
    value: SortingOptionsValuesEnum.MATURITY,
  },
  {
    label: 'Producer A-Z',
    value: SortingOptionsValuesEnum.PROPERTY_ASC,
  },
  {
    label: 'Producer Z-A',
    value: SortingOptionsValuesEnum.PROPERTY_DESC,
  },
];
