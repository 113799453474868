














import { defineComponent } from '@nuxtjs/composition-api';
import FooterSlim from '@theme/components/organisms/FooterSlim.vue';
import HeaderSlim from '@theme/components/organisms/HeaderSlim.vue';
import IconSprite from '~/components/General/IconSprite.vue';

export default defineComponent({
  name: 'SlimLayout',
  components: {
    IconSprite,
    FooterSlim,
    HeaderSlim,
  },
  transition: 'fade',
});
